import React, { useEffect, useState } from 'react';
import { Add, Launch, Replay, Visibility, CheckCircle } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import CustomCard from 'components/common/CustomCard';
import Loader from 'components/common/Loader';
import CustomChip from 'components/CustomChip';
import { displayNotification, getBaseURL, sendAPIRequest } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ViewLogsModal from 'components/modals/ViewLogsModal';
import { LIST_DETAIL_ELEMENTS } from 'constants';

const StandardCardComponent = ({ item = {}, roleId = '', setStandardData, publisherPermission, isPublishDisabled, handleClickStandard = () => { }, handleRevertStandard = () => { }, finalPublishing = () => { }, areAllStatusesValidated = () => { }, getPublishingStandards = () => { }, isActiveProcess = false, editingPermission = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState([undefined, null, 0, 1].includes(item.catalog_status));
    const [viewModal, setViewModal] = useState(false);
    const [structureId, setStructureId] = useState(null);
    // const [editingPermission, setEditingPermission] = useState({
    //     TC: false,
    //     TM: false,
    //     AC: false,
    //     AM: false,
    // });
    // useEffect(() => {
    //     editingCompletePermission()
    //     const interval = setInterval(() => {
    //         editingCompletePermission()

    //     }, 30 * 1000);
    //     return () => clearInterval(interval);
    // }, []);

    const getColorClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code";
            case "TM":
                return "btn-terrestrial-manual";
            case "AC":
                return "btn-aquatic-code";
            case "AM":
                return "btn-aquatic-manual";
            default:
                return "btn-default";
        }
    };
    const getColorDisabledClass = (structureCode) => {
        switch (structureCode) {
            case "TC":
                return "btn-terrestrial-code-default";
            case "TM":
                return "btn-terrestrial-manual-default";
            case "AC":
                return "btn-aquatic-code-default";
            case "AM":
                return "btn-aquatic-manual-default";
            default:
                return "btn-default";
        }
    };
    const [colorClass, setColorClass] = useState(getColorClass(item.structure_code))
    const [disabledColorClass, setDisabledColorClass] = useState(getColorDisabledClass(item.structure_code))
    const viewLogs = (id = null) => {
        setViewModal(true);
        setStructureId(id)
    }

    // const editingCompletePermission = async (id = '') => {
    //     try {
    //         const ret = await sendAPIRequest(`/api/doc-transfer/editing-complete-permission/`);
    //         const res = await ret.json();
    //         if (res.success && res.status === 200) {
    //             setEditingPermission(res.data);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //         displayNotification('error', 'Failed to load Permission for Editing complete');
    //     }
    // }

    const returnShortName = (standard_type = '', animal_type) => {
        try {
            let stan = '';
            let anima = ''
            if (standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes) {
                stan = "C"
            } else {
                stan = "M"
            }
            if (animal_type === LIST_DETAIL_ELEMENTS.animal_type.terrestrial) {
                anima = "T"
            } else {
                anima = "A"
            }
            return anima + stan;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const handlePublish = async (standard_type_id = '') => {
        if (!window.confirm('Are you sure you want to publish standards in all 3 languages?')) {
            return;
        }
        // setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/handle-drafting/pre-publication`, 'POST', {
                "standard_type_id": standard_type_id,
            })
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setStandardData([]);
                getPublishingStandards();
                displayNotification('success', 'Process created will finished soon')
            } else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
        }

    }
    return (

        < >
            <div className="row d-flex justify-content-between"
                disabled={[undefined, null, 0, 1].includes(item?.catalog_status)}
            >
                {/* {standardData?.map((item) =>  */}
                {
                    <>
                        <div className={`${isDisabled ? '' : 'st-card'} `}>
                            <span
                                className={`d-block text-center ${isDisabled ? disabledColorClass : colorClass} my-2 py-4 px-3 shadow-sm ${isDisabled ? '' : ''}`}
                                style={{
                                    pointerEvents: isDisabled ? "none" : "auto",
                                    opacity: isDisabled ? 0.6 : 1
                                }}
                            >
                                <span className="d-block py-15 card-title4">{item.structure_name.toUpperCase()}</span>
                                <img
                                    src={getBaseURL("/assets/icons/lines.png")}
                                    alt="Hover Image"
                                    className="hover-image"
                                />
                            </span>
                        </div>
                        <div className=''>
                            {
                                [2].includes(item.catalog_status) && item.structure_status === 1 &&
                                <button class="btn ms-0 my-2 btn--primary font-custom fs-6 w-100"
                                    onClick={() => handleClickStandard(item)}
                                    disabled= {isActiveProcess}
                                >
                                    {t("-1", "Create New Edition")}
                                    <Add className='ms-2' />
                                </button>
                            }
                            {
                                item.structure_status >= 3 &&
                                <button class="btn my-2 ms-0 btn--primary font-custom fs-6 w-100"
                                    onClick={() => navigate(`/portal/publishing-standards-details?id=${item?.id}`)}
                                    disabled= {isActiveProcess}
                                >
                                    {t("-1", "View Content")}
                                    <Visibility className='ms-2' />
                                </button>
                            }
                            {
                                editingPermission && item.structure_status === 3 &&
                                <button class="btn ms-0 my-2 w-100 ms-0 btn--primary font-custom fs-6 w-100"
                                    onClick={() => handlePublish(item.id)}
                                    disabled= {isActiveProcess}
                                >
                                    {t("-1", "Editing Complete")}
                                    <CheckCircle className='ms-2' />
                                </button>
                            }
                            <div className={item?.migrated_year !== null ? 'custom-card-front ms-0 my-2 w-100' : ''}>
                                {item?.migrated_year !== null ? (
                                    <div>
                                        <h6>
                                            EN:
                                            <span className="ps-10">
                                                {item?.status_data?.in_eng ? (
                                                    <CustomChip
                                                        type={
                                                            item.status_data.in_eng === 'VALIDATED'
                                                                ? 'blue'
                                                                : item.status_data.in_eng === 'GLOSSARY IMPLEMENTATION'
                                                                    ? 'orange'
                                                                    : item.status_data.in_eng === 'PDF GENERATION'
                                                                        ? 'yellow'
                                                                        : item.status_data.in_eng === 'PUBLISHED'
                                                                            ? 'primary'
                                                                            : item?.structure_status === 2
                                                                                ? 'red'
                                                                                : item?.structure_status === 3
                                                                                    ? 'green'
                                                                                    : item?.structure_status === 5
                                                                                        ? 'green'
                                                                                        : 'orange'
                                                        }
                                                        label={item.status_data.in_eng}
                                                    />
                                                ) : (
                                                    <CircularProgress size={12} />
                                                )}
                                            </span>
                                        </h6>
                                        <h6>
                                            FR:
                                            <span className="ps-10">
                                                {item?.status_data?.in_fr ? (
                                                    <CustomChip
                                                        type={
                                                            item.status_data.in_fr === 'VALIDATED'
                                                                ? 'blue'
                                                                : item.status_data.in_fr === 'GLOSSARY IMPLEMENTATION'
                                                                    ? 'orange'
                                                                    : item.status_data.in_fr === 'PDF GENERATION'
                                                                        ? 'yellow'
                                                                        : item.status_data.in_fr === 'PUBLISHED'
                                                                            ? 'primary'
                                                                            : item?.structure_status === 2
                                                                                ? 'red'
                                                                                : item?.structure_status === 3
                                                                                    ? 'green'
                                                                                    : item?.structure_status === 5
                                                                                        ? 'green'
                                                                                        : 'orange'
                                                        }
                                                        label={item.status_data.in_fr}
                                                    />
                                                ) : (
                                                    <CircularProgress size={12} />
                                                )}
                                            </span>
                                        </h6>
                                        <h6>
                                            SP:
                                            <span className="ps-10">
                                                {item?.status_data?.in_sp ? (
                                                    <CustomChip
                                                        type={
                                                            item.status_data.in_sp === 'VALIDATED'
                                                                ? 'blue'
                                                                : item.status_data.in_sp === 'GLOSSARY IMPLEMENTATION'
                                                                    ? 'orange'
                                                                    : item.status_data.in_sp === 'PDF GENERATION'
                                                                        ? 'yellow'
                                                                        : item.status_data.in_sp === 'PUBLISHED'
                                                                            ? 'primary'
                                                                            : item?.structure_status === 2
                                                                                ? 'red'
                                                                                : item?.structure_status === 3
                                                                                    ? 'green'
                                                                                    : item?.structure_status === 5
                                                                                        ? 'green'
                                                                                        : 'orange'
                                                        }
                                                        label={item.status_data.in_sp}
                                                    />
                                                ) : (
                                                    <CircularProgress size={12} />
                                                )}
                                            </span>
                                        </h6>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {item?.migrated_year !== null && [2, 4, 6, 8].includes(item?.structure_status) ? (
                            // <CustomChip
                            //     label={
                            //         <Box
                            //             display="flex"
                            //             alignItems="center"
                            //             sx={{
                            //                 fontSize: '14px',
                            //             }}
                            //         >

                            //             View Logs
                            //         </Box>}
                            //     type="blue"
                            //     sx={{
                            //         width: "100%",
                            //         marginY: '5px',
                            //         fontSize: '14px',
                            //     }}
                            // />
                            <div className='cursor-pointer'
                                onClick={() => {
                                    viewLogs(item.id);
                                }}
                            >
                                <Launch color='primary' />
                                <span className='text-decoration-underline fs-5'>
                                    View Logs
                                </span>
                            </div>
                        ) : null}
                        {roleId === 2 ? (
                            item?.migrated_year !== null && (
                                <CustomChip
                                    label={
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            sx={{
                                                fontSize: '14px',
                                            }}
                                        >
                                            <Replay sx={{ marginRight: 1, fontSize: '20px' }} />
                                            {item.structure_status === 8 ? (
                                                <Loader message="Reverting..." style={{ color: "#fff" }} />
                                            ) : (
                                                "Revert Standard"
                                            )}
                                        </Box>
                                    }
                                    type="red"
                                    onClick={() => handleRevertStandard(item.id)}
                                    // disabled={item.structure_status === 8}
                                    disabled={isActiveProcess}
                                    sx={{
                                        width: "100%",
                                        marginY: '5px',
                                    }}
                                />
                            )
                        ) : null}
                    </>
                }
            </div>
            {viewModal && (
                <ViewLogsModal
                    open={viewModal}
                    standardId={structureId}
                    handleClose={() => {
                        setViewModal(false);
                    }}
                />
            )}
        </>
    )
}

export default StandardCardComponent
