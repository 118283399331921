import { GridActionsCellItem } from "@mui/x-data-grid";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import { displayNotification, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "redux/generalSlice";

const CommoditiesDatabase = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [syncing, setSyncing] = useState(false);

    useEffect(() => {
        dispatch(setCurrentPage("administration"));
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/get-search-commodities-database`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setData(res.data);
            }
        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            if (!window.confirm('Are you sure you want to delete?')) {
                return;
            }
            const ret = await sendAPIRequest(`/api/commodities-animals/${id}`, 'DELETE');
            const res = await ret.json();
            if (res.success && res.status === 200) {
                await loadData();
                displayNotification('success', 'Successfully deleted data [B]');
            } else {
                displayNotification('error', 'Failed to delete data [B]');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to delete data [F]');
        }
    }

    const handleSync = async () => {
        setSyncing(true);
        try {
            const ret = await sendAPIRequest(`/api/commodities-animals/sync`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification("success", 'Successfully synced with structure');
                await loadData();
            } else {
                displayNotification("error", 'Failed to sync with structure');
            }
        } catch (err) {
            console.error({ err });
            displayNotification('error', 'Failed to sync data [F]');
        }
        setSyncing(false);
    }

    return (
        <div>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title1 lh-1">{t("229", "Commodities Database")}</div>
                        <div className="d-flex justify-content-end">
                            {/* <Button
                                variant='outline'
                                onClick={handleSync}
                                disabled={syncing}
                            >
                                {
                                    syncing ? (
                                        <Loader message='Syncing...' />
                                    ) : t("239","Sync With Structure")
                                }
                            </Button> */}
                            <Button
                                onClick={() => navigate('/portal/commodities-database/create')}
                            >
                                {t("237", "Add New")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
                    <DataTable
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                        rows={data}
                        className="text-white bg-primary rounded-0"
                        columns={
                            [
                                {
                                    field: "commodity_group_name",
                                    headerName: t("8", "Commodity Group"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "commodity_name",
                                    headerName: t("9", "Commodity"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "animal_name",
                                    headerName: t("10", "Animal"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "recommendation",
                                    headerName: t("25", "Recommendation"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "status_type",
                                    headerName: t("238", "Recommendation Status Type"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "disease",
                                    headerName: t("51", "Disease"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "section",
                                    headerName: t("61", "Section"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "chapter",
                                    headerName: t("43", "Chapter"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "article",
                                    headerName: t("165", "Article"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "RID",
                                    headerName: t("-1", "RID"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerClassName: "text-white bg-primary rounded-0",
                                    headerName: t("116", "Actions"),
                                    getActions: (params) => {
                                        return [
                                            <GridActionsCellItem
                                                label={ t ("132",
                                                    "Edit"
                                                )
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    navigate(`/portal/commodities-database/${params.row.id}/edit`);
                                                }}
                                            />,
                                            <GridActionsCellItem
                                                label={ t("133",
                                                    "Delete" 
                                                )
                                                }
                                                key={0}
                                                showInMenu
                                                onClick={() => {
                                                    handleDelete(params.row.id);
                                                }}
                                            />,
                                        ]
                                    }
                                },
                            ]
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default CommoditiesDatabase;