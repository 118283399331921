import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGrid, GridPagination, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

const DataTableV2 = ({
    getRowId, className, height, isLoading,
    rows, columns, hasIterator, pagination,
    hasExport, sx
}) => {

    const { t } = useTranslation();
    const authData = useSelector(s => s.auth?.data);
    const siteLanguage = useSelector(s => s.general.siteLanguageSont);

    const paginationStyles = {
        '.MuiTablePagination-root': {
            padding: '0',  
        },
        '.MuiTablePagination-select': {
            // paddingBottom: '5px',
            marginLeft: '0',
            marginRight: '0',
        },
        '.MuiSelect-icon': {
            top: '0'
        },
        '.MuiTablePagination-displayedRows ': {
            paddingLeft: '0',
            paddingRight: '0'
        },
        '.MuiTablePagination-input': {
            // paddingBottom: '10px'
        },
        '.MuiTablePagination-actions .MuiIconButton-root': {
            paddingX: '0',
            marginLeft: '0',
            paddingBottom: '10px'
        },
    };

    const CustomToolbar = ({ paginationEnabled }) => {
        return (
            // <GridToolbarContainer sx={paginationEnabled ? paginationStyles : {}}>
            //     <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
            //     {paginationEnabled && <GridPagination sx={{ marginLeft: 2 }} />}
            // </GridToolbarContainer>
            <GridToolbarContainer
            sx={{
                ...paginationEnabled ? paginationStyles : {},
                display: 'flex',  
                justifyContent: 'flex-end',  
                width: '100%'  
            }}
        >
            {paginationEnabled && <GridPagination sx={{ marginLeft: 'auto' }} />}
        </GridToolbarContainer>
        );
    };

    const getHeight = useCallback(() => {
        return rows.length === 0 || isLoading ? 250 : height;
    }, [rows, isLoading, height]);

    const memoizedRows = useMemo(() => {
        let iterator = 1;
        return rows.map(r => ({ ...r, index: iterator++ }));
    }, [rows]);

    const memoizedColumns = useMemo(() => (
        [
            ...(
                hasIterator ? [
                    {
                        flex: 0,
                        headerClassName: className,
                        filterable: false,
                        maxWidth: 38,
                    },
                ] : []
            ),
            ...columns
        ].map(column => ({
            ...column, align: column.align || 'left'
        }))
    ), [columns, hasIterator, className]);

    return (
        <div className='w-100' style={{ maxWidth: '100%', height: getHeight() }}>
            <DataGrid
                getRowId={getRowId}
                rows={memoizedRows}
                columns={memoizedColumns}
                autoHeight
                initialState={{
                    pagination: { paginationModel: { pageSize: 15, page: 0 } },
                }}
                slots={{
                    toolbar: () => <CustomToolbar paginationEnabled={pagination} />,
                    moreActionsIcon: () => <i className='fas fa-ellipsis-h fa-1-5x text-primary' />
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                disableRowSelectionOnClick
                loading={isLoading}
                getRowHeight={() => 'auto'}
                density='standard'
                pageSizeOptions={[15, 25, 50, 100]}
                sx={{
                    display: 'grid block',
                    gridTemplateRows: 'auto 1fr auto',
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                        marginBottom: 0
                    },
                    '& .MuiDataGrid-main': {
                        backgroundColor: '#fff'
                    },
                    '& .MuiDataGrid-footerContainer': {
                        display: 'none'  // This line hides the default footer
                    },
                    ...sx
                }}
                disableColumnMenu={true}
                pagination={pagination}
            />
        </div>
    );
};

DataTableV2.defaultProps = {
    getRowId: () => {},
    className: '',
    height: 'auto',
    rows: [],
    columns: [],
    isLoading: false,
    hasIterator: true,
    pagination: true,
    hasExport: false,
    sx: {}
};

export default DataTableV2;
