import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { LIST_DETAIL_ELEMENTS } from 'constants';
import { objectToFormData, sendAPIRequest, displayNotification } from 'helpers';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StructureNavigationComponent from 'pages/frontend/components/StructureNavigationComponent';

import { BASE_URL } from "constants";

function DraftLinkModal({ open, handleClose, id, editLinkId, draft_id, currentArticleId, setSelectedLanguage, is_modify, HTMLContentId }) {

    const { t } = useTranslation();
    const initFormData = { content_id: HTMLContentId, draft_id: draft_id, language_id: LIST_DETAIL_ELEMENTS.languages.english, animal_type: 7, animal_text: "terrestrial-code", standard_type: LIST_DETAIL_ELEMENTS.standard_type.codes };

    const [formData, setFormData] = useState(initFormData);
    const [reloadState, setReloadState] = useState({});
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '3px solid #ff4815',
        boxShadow: 24,
        // p: 4,
        overflowY: 'auto',
    };


    useEffect(() => {
        setFormData({ ...formData, target: (formData.section ?? '') + '.' + (formData.chapter ?? '') + '.' + (formData.article ?? '') })
    }, [JSON.stringify(formData)])


    useEffect(() => {
        if (reloadState.language_id) {
            setSelectedLanguage(LIST_DETAIL_ELEMENTS.languages[reloadState.language_id]);
        }
    }, [reloadState]);

    const handleSave = async () => {
        try {
            const data = {
                content_id: HTMLContentId,
                draft_id: !is_modify ? formData.draft_id : '',
                language_id: reloadState.language_id,
                animal_type: reloadState.animal_type,
                standard_type: reloadState.standard_type,
                target: formData.target,
                part: reloadState?.volume?.area_vol_no ? reloadState?.volume?.area_vol_no : reloadState?.chapter?.area_vol_no,
                section: reloadState?.section?.section_no ? reloadState?.section?.section_no : reloadState?.chapter?.section_no,
                chapter: reloadState?.chapter?.chapter_no,
                article: reloadState?.sub_section?.ref_number ?? "",
                link_text: formData.link_text,
                draft_structure_id: is_modify ? draft_id : ''
            };
            const ret = editLinkId == 0 ? await sendAPIRequest('/api/chapter-linking/create', 'POST', objectToFormData(data)) : await sendAPIRequest(`/api/chapter-linking/update/${editLinkId}`, 'PUT', objectToFormData(data))
            const res = await ret.json();
            if (res.success && res.status === 200) {
                displayNotification('success', 'Successfully saved Link');
                handleClose(null, res.data);
                // navigator.clipboard.writeText(`draft-chapter-preview/get-chapter-preview/?linking_references=${res?.data?.linking_data?.id}`);
                navigator.clipboard.writeText(`${BASE_URL}/portal/draft-chapter-preview/get-chapter-preview/?linking_references=${res?.data?.linking_data?.id ?? ""}`);
       
                displayNotification("success", "Url copied on clipboard.");
                setFormData(initFormData);
            }
            else if (res.success && res.status === 409) {
                displayNotification('warning', 'Link already exists');
            }
        } catch (error) {
            console.log({ error });
        }
    }


    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>{id}</div>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add Cross Links
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <StructureNavigationComponent
                        config={
                            {
                                is_display_header: false,
                                is_display_structures: true,
                                is_display_area: true,
                                is_display_sections: true,
                                is_display_chapters: true,
                                is_display_subsections: true,
                                is_display_languages: true,
                                is_display_chapter_name: true,
                                is_display_standard_heading: true,
                            }
                        }
                        model={is_modify}
                        reloadData={(state) => setReloadState(state)}
                    />
                    {/* <Container>
                        <CustomCard>
                            <CardBody>
                                <div className='row mt-3 g-0 px-4'>
                                    <div className='col-12 mb-3 d-flex'>
                                        <TextFieldCustom
                                            variant="outlined"
                                            id="link_text"
                                            value={formData.link_text}
                                            label={t("-1", "Link Text")}
                                            onChange={(event) => {
                                                setFormData({ ...formData, link_text: event.target.value })
                                            }}
                                            fullWidth
                                            rows={4}
                                            required={true}
                                            sx={{
                                                backgroundColor: "white",
                                                color: "white",
                                                border: "#ff4815",
                                                width: "99%",
                                            }}
                                            size='small'
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </CustomCard>
                    </Container> */}

                    <div className='row'>
                        <div className="col-12 text-center mt-3">
                            <button
                                className="btn btn--outline font-custom fs-6 me-2"
                                onClick={() => {
                                    setFormData(initFormData);
                                    handleClose();
                                }}
                            >
                                {t("201", "Close")}
                            </button>
                            <button
                                className="btn btn--primary font-custom fs-6"
                                onClick={() => {
                                    handleSave();
                                }}
                            >
                                {t("-1", "Generate Link")}
                            </button>
                        </div>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default DraftLinkModal
