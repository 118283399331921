import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { displayNotification, sendAPIRequest } from 'helpers';
import DataTable from 'components/DataTable';
import HtmlRenderer from 'components/HtmlRenderer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#bbbbbb',
        borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#bbbbbb',
    },
};
const ViewLogsModal = ({ open = false, handleClose, standardId = null }) => {
    const { t } = useTranslation();
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        let interval;
        if (standardId) {
            getLogs();
            interval = setInterval(() => {
                getLogs();
            }, 5000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [standardId]);

    const getLogs = async () => {
        try {
            const ret = await sendAPIRequest(`/api/processes/${standardId}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                console.log("logs:", res.data?.relationProcessWithProcessLog)
                setLogs(res.data?.relationProcessWithProcessLog)
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', 'Failed to load data');
        }
    }
    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Logs
                        </Typography>
                        <IconButton onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead className='bg-primary text-white'>
                                            <tr>
                                                <th>#</th>
                                                <th>Message</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border border-secondary'>
                                            {logs && logs.length > 0 ? (
                                                logs.map((log, index) => (
                                                    <tr key={index}>
                                                        <td className="border-dark">{index + 1}</td>
                                                        <td className="border-dark">
                                                            <HtmlRenderer
                                                                htmlContent={log.process_name}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="2" className="text-center border-dark">
                                                        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                                                            {t("-1", "No logs")}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className='row' >
                            <div className="col-12 text-center mt-3">
                                <button
                                    className="btn btn--primary  font-custom fs-6 me-2"
                                    onClick={() => handleClose(false)}
                                >
                                    {t("201", "Close")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

ViewLogsModal.defaultProps = {
    standardId: null,
};

export default ViewLogsModal
