import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';
import { Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import TextFieldCustom from 'components/TextFieldCustom';
import { displayNotification, sendAPIRequest, stripHtml } from 'helpers';
import HtmlRenderer from 'components/HtmlRenderer copy';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: 'auto',
    bgcolor: 'background.paper',
    // border: '3px solid #ff4815',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    padding: 0,
};

function AddNBModal({ open, handleClose, id, handleSaveNB }) {

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [txt, setTxt] = useState("");
    const [orgTxt, setOrgTxt] = useState("");
    const [NBId, setNBId] = useState(null);
    const handleAddNewNb = async () => {
        if (!txt) {
            displayNotification('warning', 'Please filed required field');
            return;
        }
        setIsLoading(true);
        try {
            await handleSaveNB(txt, open?.id ?? '');
            handleCloseModel();
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Failed to update glossary term');
        }
        setIsLoading(false);
    };

    const handleCloseModel = () => {
        try {
            setTxt("");
            handleClose();
            setNBId(null)
        } catch (error) {
            console.log({ error });
        }
    }

    const getExistingNB = async () => {
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(`/api/draft-content/get-nb/${open?.id}`);
            const res = await ret.json()
            if (res.success && res.status === 200) {
                if (res?.data) {
                    setOrgTxt(res?.data?.standard_content)
                    const text = stripHtml(res?.data?.standard_content);
                    setTxt(text);
                    setNBId(res?.data?.id);
                }
                else {
                    setTxt('');
                };
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Error loading data [F]');
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (open?.open) {
            getExistingNB();
        }
    }, [open])

    const handleDeleteNB = async () => {
        if (!window.confirm('Are you sure you want to delete this NB?')) {
            return;
        }
        try {
            const ret = await sendAPIRequest(`/api/draft-content/delete-nb/${NBId}`, 'DELETE');
            const res = await ret.json()
            if (res.success && res.status === 200) {
                setNBId(null);
                setTxt('');
                displayNotification('success', 'NB deleted successfully');
            } else {
                displayNotification('error', 'Error loading data [B]');
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Error loading data [F]');
        }
    }

    return (
        <>
            <Modal
                open={open.open}
                onClose={handleCloseModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {NBId !== null ? 'Edit NB' : 'Add NB'}
                        </Typography>
                        <IconButton onClick={handleCloseModel}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className="row">
                            {NBId !== null ?
                                <div className="col-12 d-flex justify-content-between mb-3">
                                    <HtmlRenderer
                                        tag={"span"}
                                        htmlContent={orgTxt}
                                        abc
                                    />
                                    <Tooltip title="Delete NB" placement="top">
                                        <i className="fa-solid fa-trash text-primary cursor-pointer"
                                            onClick={() => { handleDeleteNB(); }}
                                        ></i>
                                    </Tooltip>
                                </div>
                                : null}
                            <div className="col-12">
                                <div className='fw-bold fs-3'>{t("-1", NBId !== null ? 'Edit Text' : 'Add Text')}:</div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Chapter"
                                    fullWidth
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    value={txt}
                                    onChange={(e) => setTxt(e.target.value)}
                                    size='large'
                                    multiline={true}
                                />
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='d-flex justify-content-center'>
                                <div className="text-center">
                                    <button
                                        className="btn btn--primary font-custom fs-6"
                                        onClick={() => {
                                            handleCloseModel();
                                        }}
                                    >
                                        {t("-1", "Cancel")}
                                    </button>
                                </div>
                                <div className="text-center ms-2">
                                    <button
                                        className="btn btn--outline font-custom fs-6"
                                        onClick={handleAddNewNb}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default AddNBModal
