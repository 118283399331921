import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HtmlRenderer from "components/HtmlRenderer";
import Spinner from "components/spinner/Spinner";
import { LIST_DETAIL_ELEMENTS } from "constants";
import { sendAPIRequest } from "helpers";
import Container from "components/common/Container";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChapterPreview = () => {
    const [location, setLocation] = useSearchParams();
    const { t } = useTranslation();

    const { section_number, language_id, standard_type, animal_type, chapter_number, article_number, area_number, linking_references } = {
        section_number: location.get('section_number'),
        area_number: location.get('area_number'),
        language_id: location.get('language_id'),
        standard_type: location.get('standard_type'),
        animal_type: location.get('animal_type'),
        chapter_number: location.get('chapter_number'),
        article_number: location.get('article_number'),
        linking_references: location.get('linking_references'),
    };
    // is store article number if exsit in linking
    const [articleId, setArticleId] = useState('')
    const [chapterData, setChapterData] = useState('');
    const [isLoading, setIsLoading] = useState({
        loading_chapter: false,
    });

    useEffect(() => {
        getChapterData();
        getLinkDetail();
    }, [area_number, section_number, chapter_number, article_number, standard_type, animal_type, language_id, linking_references]);

    useEffect(() => {
        if (chapterData && article_number) {
            const test = chapterData?.children?.find((i) => i.ref_number == article_number);
            const headingElement = document.getElementById(`article-${test?.id}`);
            if (headingElement) {
                headingElement.scrollIntoView({ behavior: "smooth" });
            } else {
                console.error('Element not found:', `article-${article_number}`);
            }
        }
        if (articleId) {
            const arti = chapterData?.children?.find((i) => i.ref_number == articleId);
            const headingElements = document.getElementById(`article-${arti?.id}`);
            if (headingElements) {
                headingElements.scrollIntoView({ behavior: "smooth" });
            } else {
                console.error('Element not found:', `article-${article_number}`);
            }
        }
    }, [chapterData, article_number, articleId]);

    const getChapterData = async () => {
        setIsLoading({ ...isLoading, loading_chapter: true });
        try {
            const ret = await sendAPIRequest(`/api/contents/chapter-against-number/?area_number=${area_number ?? ''}&section_number=${section_number ?? ''}&chapter_number=${chapter_number ?? ''}&article_number=${article_number ?? ''}&standard_type=${standard_type ?? ''}&animal_type=${animal_type ?? ''}&language_id=${language_id ?? ''}&linking_references=${linking_references ?? ""}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setChapterData(res.data);
            } else {
                setChapterData('');
            }
        } catch (e) {
            console.error({ e });
        }
        setIsLoading({ ...isLoading, loading_chapter: false });
    };
    const getLinkDetail = async () => {
        try {

            if (linking_references) {
                const linkDetailResponse = await sendAPIRequest(`/api/chapter-linking/structure-by-reference/${linking_references}`);
                const res = await linkDetailResponse.json();
                if (res.success && res.status === 200) {
                    if (res.data.article) {
                        setArticleId(res.data.article);
                    } else {
                        setArticleId('');
                    }
                }
            }

        } catch (error) {
            console.log({ error });
        }
    }

    return (
        <>
            <Container>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                    width="100%"
                >
                    {
                        isLoading.loading_chapter ? (
                            <Box sx={{ textAlign: 'center' }}>
                                <Spinner message={t('-1', 'Loading Data, Please wait...')} />
                            </Box>
                        ) : (
                            chapterData ? (
                                <>
                                    <Box px={2} pb={2}>
                                        <Box key={`section-${chapterData.parent.id}`}>
                                            <Typography
                                                textAlign="center"
                                                pt={2}
                                                style={{
                                                    letterSpacing: '2px',
                                                    textTransform: 'uppercase'
                                                }}
                                                key={chapterData.parent.id}
                                            >
                                                {`Section ${linking_references
                                                    ? chapterData.standards_structures_revised.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                                        ? chapterData?.standards_structures_revised?.section_no
                                                        : chapterData?.standards_structures_revised?.area_vol_no + "." + chapterData?.standards_structures_revised?.chapter_no
                                                    : standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                                        ? chapterData?.standards_structures_revised?.section_no
                                                        : chapterData?.standards_structures_revised?.area_vol_no + "." + chapterData?.standards_structures_revised.section_no
                                                    }`}

                                            </Typography>
                                            <Typography
                                                className='chapter-heading'
                                                textAlign="center"
                                                py={0}
                                                style={{
                                                    letterSpacing: '2px',
                                                    textTransform: 'uppercase'
                                                }}
                                                key={`name-${chapterData.parent.id}`}
                                            >
                                                <HtmlRenderer
                                                    tag={'span'}
                                                    className="fs-6"
                                                    htmlContent={chapterData.standards_structures_revised?.section_name}
                                                />
                                            </Typography>
                                            <Divider
                                                variant="middle"
                                                sx={{
                                                    width: '50%',
                                                    margin: 'auto',
                                                    marginTop: '16px',
                                                    marginBottom: '6px',
                                                    backgroundColor: '#000',
                                                }}
                                            />
                                            <Divider
                                                variant="middle"
                                                sx={{
                                                    width: '50%',
                                                    margin: 'auto',
                                                    backgroundColor: '#000',
                                                }}
                                            />
                                        </Box>
                                        <Box key={`chapter-${chapterData.id}`}>
                                            <Typography
                                                my={0}
                                                py={0}
                                                textAlign="center"
                                                p={3}
                                                style={{
                                                    letterSpacing: '2px',
                                                    textTransform: 'uppercase'
                                                }}
                                                key={chapterData.id}
                                            >
                                                {`Chapter ${linking_references
                                                    ? chapterData.standards_structures_revised.standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                                        ? chapterData?.standards_structures_revised?.section_no + "." + chapterData?.standards_structures_revised?.chapter_no
                                                        : chapterData?.standards_structures_revised?.area_vol_no + "." + chapterData?.standards_structures_revised?.section_no + "." + chapterData?.standards_structures_revised?.chapter_no
                                                    : standard_type === LIST_DETAIL_ELEMENTS.standard_type.codes
                                                        ? chapterData?.standards_structures_revised?.section_no + "." + chapterData?.standards_structures_revised?.chapter_no
                                                        : chapterData?.standards_structures_revised?.area_vol_no + "." + chapterData?.standards_structures_revised?.section_no + "." + chapterData?.standards_structures_revised?.chapter_no
                                                    }`}

                                            </Typography>
                                            <Typography
                                                textAlign="center"
                                                style={{
                                                    letterSpacing: '2px',
                                                    textTransform: 'uppercase'
                                                }}
                                                key={`name-${chapterData.id}`}
                                                className='chapter-heading'
                                            >
                                                <HtmlRenderer
                                                    tag={'p'}
                                                    className="document-chapitre-intitule"
                                                    htmlContent={chapterData?.standard_content}
                                                />
                                            </Typography>
                                        </Box>
                                        {
                                            chapterData.children && chapterData.children.length > 0 &&
                                            chapterData.children.map(d =>
                                                <div
                                                    key={`article-${d.id}`}
                                                    id={`article-${d.id}`}
                                                    data-myattribute={d.ref_number}
                                                >
                                                    <HtmlRenderer
                                                        tag={"div"}
                                                        className="py-4 text-center"
                                                        htmlContent={d?.standard_content}
                                                    />
                                                    {
                                                        d.html_contents && d.html_contents.length > 0 ? (
                                                            d.html_contents.map((child) => (
                                                                <div
                                                                    className="px-4 text-justified"
                                                                    key={`art-child-${child.id}`}
                                                                >
                                                                    <HtmlRenderer
                                                                        tag={"div"}
                                                                        htmlContent={child.content}
                                                                    />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            d.children &&
                                                            d.children.map(child =>
                                                                <div key={`general-${child.id}`} id={`${child.id}`}>
                                                                    <HtmlRenderer
                                                                        tag={"div"}
                                                                        className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === child.text_type ? "py-4 fw-bold" : "py-4"}
                                                                        htmlContent={child?.standard_content}
                                                                    />
                                                                    {
                                                                        child.html_contents &&
                                                                        child.html_contents.length > 0 &&
                                                                        child.html_contents.map((c) => (
                                                                            <div
                                                                                className={`d-flex ${c.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text && 'justify-content-end pt-3'}`}
                                                                            >
                                                                                <div className={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "text-justified" : 'justify-content-end'}>
                                                                                    <HtmlRenderer
                                                                                        tag={LIST_DETAIL_ELEMENTS.heading_types.general_heading === c.text_type ? "div" : "p"}
                                                                                        htmlContent={c.content}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            )
                                        }
                                    </Box>
                                </>
                            ) : (
                                <Box p={10}>
                                    <Typography
                                        variant='h6'
                                    >
                                        {t("-1", "No Data Found")}
                                    </Typography>
                                </Box>
                            )
                        )
                    }
                </Box>
            </Container>
        </>
    );
}

ChapterPreview.defaultProps = {
    area_number: '',
    section_number: '',
    chapter_number: '',
    article_number: '',
    standard_type: '',
    animal_type: '',
    language_id: '',
};

export default ChapterPreview;
