import React, { useRef, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "redux/generalSlice";
import { useNavigate, useParams } from "react-router-dom";
import { displayNotification, getStyleName, sendAPIRequest, getAPIURL } from "helpers";
import Spinner from "components/spinner/Spinner";
import EditModal from "components/modals/EditModal";
import { LIST_DETAIL_ELEMENTS } from "constants";
import collect from "collect.js";
import DropDownMenu from "./DropDownMenu";
import ManualDropDownMenu from "./ManualDropDownMenu";
import IgnoreDataModal from "components/modals/IgnoreDataModal";
import ParentChildLinking from "./ParentChildLinking";
import { useSearchParams } from "react-router-dom";
import StyleDropDownMenu from "./StyleDropDownMenu";
import SplitModal from "components/modals/SplitModal";
import TextFieldCustom from "components/TextFieldCustom";
import cloneDeep from "lodash/cloneDeep";
import HtmlRenderer from "components/HtmlRenderer";
import ManualParentChildMapping from "./ManualParentChildMapping";
import SetImageModal from "components/modals/SetImageModal";
import { generateOptionsFromData } from '../../helpers'
import SetImageSrcModel from "components/modals/SetImageSrcModel";
import SetTextTypeModals from "components/modals/SetTextTypeModals";


const DataManualMapping = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("page");
  const showMetaData = searchParams.get("meta-data") === "true" ? true : false;
  const isMigrated = searchParams.get("is-migrated") === "true" ? true : false;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    load_data: true,
    save_data: false,
  });

  const editRef = useRef();
  const setImageRef = useRef();
  const splitRef = useRef();
  const [ignoreData, setIgnoreData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("link");
  const [styleList, setStyleList] = useState([]);
  const [matchedCount, setMatchedCount] = useState(0);
  const [tags, setTags] = useState([]);
  const [isCracking, setIsCracking] = useState(false)
  const [editVaue, setEditValue] = useState("")
  const [is_cracked, setIs_cracked] = useState(false)
  const authData = useSelector((s) => s.auth.data);

  const [imagesLoading, setImagesLoading] = useState(false)
  const [selectImage, setSelectedImage] = useState({
    value: '',
    label: ''
  })

  const [getAllImages, setGetAllImages] = useState(false)
  const [markedString, setMarkedString] = useState('')
  const [imagesIds, setImagesIds] = useState([])
  const [currentImage, setCurrentImage] = useState({})
  const [documentImages, setDocumentImages] = useState([])

  const [imageContent, setImageContent] = useState('')
  const [checkModal, setCheckModal] = useState(false)
  const [selectedImageInfo, setSelectedImageInfo] = useState({
    child: false,
    parentIndex: null,
    elementIndex: null
  })
  const [textTypeModal, setTextTypeModal] = useState(false);
  const [textTypeDetail, setTextTypeDetail] = useState({
    id: '',
    text_type: ''
  })
  const [reload ,setReload] = useState(false);

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("data-migration"));
    loadStyleList();
    // loadData();
    // loadIgnoredData();
    loadHtmlData();
  }, [reload]);

  const goBack = () => {
    navigate(`/portal/data-migrations`);
  };


  const imageOptions = useMemo(() => {
    return generateOptionsFromData(documentImages, {
      value: "id",
      label: "image_name",
    });
  }, [documentImages]);


  const saveContent = async () => {
    setIsCracking(true)
    try {
      const ret = await sendAPIRequest(`/api/html-contents/add-content`, "POST", {
        content: tags,
        docId: id
      });
      const res = await ret.json();
      if (res.success && res.status === 200) {
        await loadHtmlData()
        displayNotification("success", "Saved Content Successfully")
      } else {
        displayNotification("error", "SomeThing went wrong")
      }
    } catch (e) {
      console.log("error", { e });
    }
    setIsCracking(false)
  };

  const loadHtmlData = async () => {

    try {
      setIsLoading({ ...isLoading, load_data: true });
      const ret = await sendAPIRequest(`/api/html/manual-crack/${id}?skip_types=${LIST_DETAIL_ELEMENTS.text_types.section_name},${LIST_DETAIL_ELEMENTS.text_types.chapter_name}`);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setTags(res.data);
        setIs_cracked(res.flag.is_cracked)
        setIsLoading({ ...isLoading, load_data: false });
      }
    } catch (e) {
      console.log({ e });
      setIsLoading({ ...isLoading, load_data: false });
    }

  };

  const loadStyleList = async () => {
    try {
      const ret = await sendAPIRequest(`/api/lists/master/9`);
      const res = await ret.json();
      if (res.success && ret.status === 200) {
        setStyleList(res.data);
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const updateContentAPI = async (info, id, is_child = false) => {
    try {
      let ret;
      if (is_child) {
        ret = await sendAPIRequest(
          `/api/content-paragraphs/${id}`,
          "PUT",
          info
        );
      } else {
        ret = await sendAPIRequest(`/api/contents/${id}`, "PUT", info);
      }
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully updated content [B]");
      } else {
        displayNotification("error", "Failed to update content [B]");
      }
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to update content [F]");
    }
  };



  const updateContent = async (index, index2, updatedContent) => {
    let temp = [...data];
    if (index2 != null) {
      temp[index].children[index2].json_content = updatedContent;
      isMigrated &&
        (await updateContentAPI(
          { content_text: updatedContent },
          temp[index].children[index2].id,
          true
        ));
    } else {
      temp[index].json_content = updatedContent;
      isMigrated &&
        (await updateContentAPI(
          { standard_content: updatedContent },
          temp[index].id
        ));
    }
    setData([...temp]);
  };

  const setContentNumberAPI = async (id, info) => {
    try {
      const ret = await sendAPIRequest(`/api/contents/${id}`, "PUT", info);
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully marked [B]");
        return true;
      } else {
        displayNotification("error", "Failed to mark [B]");
        return false;
      }
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to mark [F]");
      return false;
    }
  };

  const setContentNumber = async (index, index2, number, role, text_type) => {
    let temp = [...data];
    if (index2 != null) {
      const content = temp[index].children[index2];
      temp[index].children = temp[index].children.filter(
        (val, key) => key != index2
      );
      content.role = role;
      content.parent_id = ![
        LIST_DETAIL_ELEMENTS.text_types.section_name,
        LIST_DETAIL_ELEMENTS.text_types.chapter_name,
      ].includes(content.text_type)
        ? getChapterID()
        : null;
      content.text_type = text_type;
      content.ref_number = number;
      const isUpdated = await setContentNumberAPI(content.id, {
        text_type: content.text_type,
        ref_number: content.ref_number,
      });
      isUpdated && temp.push(content);
    } else {
      const isUpdated = await setContentNumberAPI(temp[index].id, {
        parent_id: ![
          LIST_DETAIL_ELEMENTS.text_types.section_name,
          LIST_DETAIL_ELEMENTS.text_types.chapter_name,
        ].includes(temp[index].text_type)
          ? getChapterID()
          : null,
        text_type: text_type,
        ref_number: number,
      });
      if (isUpdated) {
        temp[index]["ref_number"] = number;
        temp[index].role = role;
        temp[index].text_type = text_type;
        if (role != LIST_DETAIL_ELEMENTS.text_types.article_heading) {
          const children = temp[index].children;
          temp[index].children = [];
          children && children.length > 0 && temp.push(...children);
        }
      }
    }
    temp = collect(temp).sortBy("offset").toArray();
    setData([...temp]);
  };

  const ignoreContentAPI = async (id, is_child = false) => {
    try {
      let ret;
      if (!is_child) {
        ret = await sendAPIRequest(`/api/contents/${id}`, "DELETE");
      } else {
        ret = await sendAPIRequest(`/api/content-paragraphs/${id}`, "DELETE");
      }
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully ignored data [B]");
      } else {
        displayNotification("error", "Failed to ignore data [B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification("error", "Failed to ignore data [F]");
    }
  };

  const ignoreContent = async (index, index2) => {
    if (window.confirm("Are you sure you want to ignore")) {
      let temp = [...data];
      let content = null;
      if (index2 != null) {
        content = temp[index].children[index2];
        temp[index].children = temp[index].children.filter(
          (val, key) => key != index2
        );
        await ignoreContentAPI(content.id, true);
      } else {
        content = temp[index];
        temp = temp.filter((val, key) => key != index);
        await ignoreContentAPI(content.id, false);
      }
      setData([...temp]);
      content && setIgnoreData([...ignoreData, content]);
    }
  };

  const restoreIgnoreDataAPI = async (content) => {
    try {
      const data = content.map((info) => ({
        text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
        standard_content: info.json_content,
        document_id: info.document_id,
        level: info.level ?? 0,
        parent_id: info.parent_id ?? null,
        offset: info.offset,
        style_id: info.style_id ?? null,
        ref_number: info.ref_number ?? null,
        createdBy: authData.id ?? null,
      }));
      const ret = await sendAPIRequest(
        `/api/contents/bulk-create`,
        "POST",
        data
      );
      const res = await ret.json();
      if (res.success && res.status === 201) {
        displayNotification("success", "Successfully completed process [B]");
        // await loadData();
      } else {
        displayNotification("error", "Failed to complete process [B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification("error", "Failed to complete process [F]");
    }
  };

  const restoreIgnoreData = async (content, selectedIDs) => {
    try {
      let temp = [...data, ...content];
      temp = collect(temp).sortBy("offset").toArray();
      setData([...temp]);
      let tempIgnoreData = [...ignoreData];
      tempIgnoreData = tempIgnoreData.filter(
        (t) => !selectedIDs.includes(t.id)
      );
      setIgnoreData([...tempIgnoreData]);
      await restoreIgnoreDataAPI(content);
      displayNotification("success", "Successfully restored data [F]");
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to restore data [F]");
    }
  };

  const handleMarkAsParagraph = async (index) => {
    try {
      let temp = [...data];
      const children = temp[index].children;
      temp[index].children = [];
      temp[index].role = "articleParagraph";
      temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_heading;
      children && children.length > 0 && temp.push(...children);
      temp = collect(temp).sortBy("offset").toArray();
      if (isMigrated) {
        const ret = await sendAPIRequest(
          `/api/contents/${temp[index].id}`,
          "PUT",
          {
            text_type: temp[index].text_type,
            standard_content: temp[index].json_content,
            document_id: temp[index].document_id,
            level: temp[index].level,
            parent_id: null,
            offset: temp[index].offset,
            style_id: temp[index].style_id,
            ref_number: temp[index].ref_number,
            updatedBy: authData.id,
          }
        );
        const res = await ret.json();
        if (res.success) {
          displayNotification("success", "Successfully marked [B]");
        } else {
          displayNotification("error", "Failed to mark [B]");
        }
      }
      setData([...temp]);
    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };

  const handleAPIMarking = async (info, is_child = false) => {
    try {
      let ret;
      const data = {
        text_type: info.text_type,
        standard_content: info.json_content,
        document_id: info.document_id,
        level: info.level,
        parent_id: info.parent_id,
        offset: info.offset,
        style_id: info.style_id,
        ref_number: info.ref_number,
        updatedBy: authData.id,
      };
      if (!is_child) {
        ret = await sendAPIRequest(`/api/contents/${info.id}`, "PUT", data);
      } else {
        ret = await sendAPIRequest(
          `/api/contents/update-content-type/${info.id}/?is_child=true`,
          "POST",
          data
        );
      }
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully marked [B]");
      } else {
        displayNotification("error", "Failed to mark [B]");
      }
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to mark [F]");
    }
  };

  const getChapterID = () => {
    const chapterID = data.filter(
      (d) => d.text_type === LIST_DETAIL_ELEMENTS.text_types.chapter_name
    )[0];
    return chapterID.id;
  };

  const handleMarking = async (index, index2, role, text_type) => {
    let temp = [...data];
    if (index2 != null) {
      const section_number = temp[index].children[index2];
      temp[index].children = temp[index].children.filter(
        (val, key) => key != index2
      );
      section_number.role = role;
      section_number.text_type = text_type;
      section_number.offset = section_number.offset ?? 1;
      temp.push(section_number);
      await handleAPIMarking(section_number, true);
    } else {
      const children = temp[index].children;
      temp[index].children = [];
      temp[index].role = role;
      temp[index].text_type = text_type;
      temp[index].offset = temp[index].offset ?? 1;
      children && children.length > 0 && temp.push(...children);
      await handleAPIMarking(temp[index], false);
    }
    temp = collect(temp).sortBy("offset").toArray();
    setData([...temp]);
  };

  const handleRadioButtonChangeState = (value) => {
    setSelectedValue(value);
  };

  const linkChildAPI = async (info) => {
    try {
      const ret = await sendAPIRequest(`/api/content-paragraphs`, "POST", info);
      const res = await ret.json();
      if (res.success && res.status === 201) {
        displayNotification("success", "Successfully linked child [B]");
      } else {
        displayNotification("error", "Failed to link child [B]");
      }
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to link child [F]");
    }
  };

  const linkHeadingWithArticle = async (article_id, heading_id) => {
    try {
      const ret = await sendAPIRequest(`/api/contents/${heading_id}`, "PUT", {
        parent_id: article_id,
      });
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully linked child [B]");
        // await loadData();
      } else {
        displayNotification("error", "Failed to link child [B]");
      }
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to link child [F]");
    }
  };

  const linkChildWithParent = async (index, id, func) => {
    try {
      let temp = [...data];
      if (func === "link") {
        let text = temp.filter((val, key) => key === parseInt(id));
        text = text.map((t) => ({ ...t, parent_id: temp[index].id }));
        if (
          temp[index].text_type ===
          LIST_DETAIL_ELEMENTS.text_types.article_heading
        ) {
          await linkHeadingWithArticle(temp[index].id, text[0].id);
        } else {
          temp[index].children.push(...text);
          temp = temp.filter((val, key) => key != parseInt(id));
          // await linkChildAPI({
          //     content_id: temp[index].id,
          // })
        }
      } else {
        if (
          temp[index].text_type ===
          LIST_DETAIL_ELEMENTS.text_types.article_heading
        ) {
          const text = temp.filter((val, key) => key === parseInt(id));
          await linkHeadingWithArticle(null, text[0].id);
        } else {
          const text = temp[index].children
            .filter((val, key) => key === parseInt(id))
            .map((t) => ({ ...t, parent_id: null }));
          temp[index].children = temp[index].children.filter(
            (val, key) => key != parseInt(id)
          );
          temp.push(...text);
        }
      }
      temp = collect(temp).sortBy("offset").toArray();
      temp[index].children = collect(temp[index].children)
        .sortBy("offset")
        .toArray();
      setData([...temp]);
      displayNotification("success", `Successfully ${func} [F]`);
    } catch (e) {
      console.log({ e });
      displayNotification("error", `Failed to ${func} [F]`);
    }
  };

  const storeData = async () => {
    if (window.confirm("Are you sure you want to save file data.")) {
      setIsLoading({ ...isLoading, save_data: true });
      try {
        const temp = collect(data).sortBy("offset").toArray();
        const ret = await sendAPIRequest(`/api/data-mapping/${id}`, "POST", {
          data: temp,
        });
        const res = await ret.json();
        if (res.success && res.status == 200) {
          displayNotification("success", "Successfully saved data [B]");
          // await loadData();
          setIgnoreData([]);
        } else {
          displayNotification("error", "Failed to save data [B]");
        }
      } catch (e) {
        console.log({ e });
        displayNotification("error", "Failed to save data [F]");
      }
      setIsLoading({ ...isLoading, save_data: false });
    }
  };

  const updateOffset = (index, index2, method) => {
    try {
      let temp = [...data];
      if (method === "increase") {
        if (index2 != null) {
          const offset = temp[index].children[index2].offset;
          if (index2 - 1 >= 0) {
            temp[index].children[index2].offset =
              temp[index].children[index2 - 1].offset;
            temp[index].children[index2 - 1].offset = offset;
          }
          temp[index].children = collect(temp[index].children)
            .sortBy("offset")
            .toArray();
        } else {
          const offset = temp[index].offset;
          if (index - 1 >= 0) {
            temp[index].offset = temp[index - 1].offset;
            temp[index - 1].offset = offset;
          }
          temp = collect(temp).sortBy("offset").toArray();
        }
      } else if (method === "decrease") {
        if (index2 != null) {
          const offset = temp[index].children[index2].offset;
          if (temp[index].children.length > index2 + 1) {
            temp[index].children[index2].offset =
              temp[index].children[index2 + 1].offset;
            temp[index].children[index2 + 1].offset = offset;
          }
          temp[index].children = collect(temp[index].children)
            .sortBy("offset")
            .toArray();
        } else {
          const offset = temp[index].offset;
          if (temp[index].children.length > index2 + 1) {
            temp[index].offset = temp[index + 1].offset;
            temp[index + 1].offset = offset;
          }
          temp = collect(temp).sortBy("offset").toArray();
        }
      }
      setData([...temp]);
    } catch (e) {
      console.log({ e });
      displayNotification("error", "Failed to update offset");
    }
  };

  const splitText = (index, index2, content) => {
    let temp = [...data];
    if (index2 !== null) {
      temp[index].children[index2].json_content = content.updated_content;
      const newContent = {
        ...temp[index].children[index2],
        children: [],
        text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
        offset: content.content_offset + 1,
        json_content: content.new_content,
      };
      temp[index].children.push(newContent);
      temp[index].children = collect(temp[index].children)
        .sortBy("offset")
        .toArray();
    } else {
      temp[index].json_content = content.updated_content;
      const newContent = {
        ...temp[index],
        children: [],
        text_type: LIST_DETAIL_ELEMENTS.text_types.paragraph,
        offset: content.content_offset + 1,
        json_content: content.new_content,
      };
      temp.push(newContent);
      temp = collect(temp).sortBy("offset").toArray();
    }
    setData([...temp]);
  };

  const styleMapping = async (id, style_id, is_content) => {
    try {
      const ret = await sendAPIRequest(`/api/styles-mapping`, "POST", {
        style_id: style_id,
        content_id: is_content ? id : null,
        content_paragraph_id: !is_content ? id : null,
      });
      const res = await ret.json();
      if (res.success && res.status === 200) {
        displayNotification("success", "Successfully marked styles [B]");
      } else {
        displayNotification("error", "Failed to style content [B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification("error", "Failed to style content [F]");
    }
  };


  const handleMarkasGLossaryTerm = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {
        temp[index].children = []
        temp[index].role = "glossary_term";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.glossary_term;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as Glossary Term [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_heading
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Heading [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsHeading = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "heading";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_heading;
        temp[index].isHandled = true;

        setTags([...temp])
        displayNotification("success", "Successfully marked as Heading [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_heading
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Heading [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsGeneralHeading = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "general_heading";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.heading_types.general_heading;
        temp[index].isHandled = true;

        setTags([...temp])
        displayNotification("success", "Successfully marked as General  Heading [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.heading_types.general_heading
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked General Heading [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkOnlineResources = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "online_resources";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_online_resource;
        temp[index].isHandled = true;

        setTags([...temp])
        displayNotification("success", "Successfully marked as Online Resources [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_online_resource
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Online Resources [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkFutherReadig = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "furthur_reading";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading;
        temp[index].isHandled = true;

        setTags([...temp])
        displayNotification("success", "Successfully marked as Further Reading [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Further Reading [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleAsMarkBibloGrapgy = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "biblography";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_biblography;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as BibloGraphy [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_biblography
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked BibloGraphy [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsParaph = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "pragraph";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_paragraph;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as ParaGraph [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_paragraph
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked ParaGraph [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }
    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkASApendexHeading = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "appendex_heading";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_appendex_heading;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as AppendexHeading [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_appendex_heading
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked AppendexHeading [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }
    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsAppendex = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "appendex";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_appendex;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as Appendex [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_appendex
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Appendex [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }



    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsFooter = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "footer";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_footer;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as Appendex [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_footer
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Footer [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }
    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkAsRefernce = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {
        temp[index].children = []
        temp[index].role = "footer";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.text_types.manual_reference;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as Refference [B]");
      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.text_types.manual_reference
        });

        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked Refference [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }
    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };
  const handleMarkASCOdeEditingDate = async (index) => {
    try {
      let temp = [...tags];
      if (!is_cracked) {

        temp[index].children = []
        temp[index].role = "code_editing_date_text";
        temp[index].text_type = LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text;
        temp[index].isHandled = true;
        setTags([...temp])
        displayNotification("success", "Successfully marked as Code Editing Date text [B]");

      } else {
        const id = temp[index].id
        const ret = await sendAPIRequest(`/api/html-contents/mark-heading?id=${id}`, "POST", {
          text_type: LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text
        });
        let res = await ret.json()
        if (res.success && res.status === 200) {
          await loadHtmlData()
          displayNotification("success", "Successfully marked as Code Editing Date text [B]");
        } else {
          displayNotification("error", "Failed to Mark content [B]");
        }
      }

    } catch (e) {
      displayNotification("error", "Failed to mark [F]");
    }
  };

  const handleManualChildLink = async (index, childId, isLinked) => {
    try {
      let temp = [...tags];
      if (childId === "") {
        return
      }

      if (!is_cracked) {

        if (!isLinked) {
          if (!temp[index]?.children[childId]) {
            displayNotification("error", "No Child Found at the Specified Index!");
            return;
          }
          let removedChild = temp[index].children.splice(childId, 1)[0];
          if (temp[index].children.length === 0) {
            delete temp[index].children;
          }
          temp.push(removedChild);
          temp.sort((a, b) => {
            const idA = a.offset - 1;
            const idB = b.offset - 1;

            if (idA < idB) {
              return -1;
            }
            if (idA > idB) {
              return 1;
            }
            return 0;
          });
          setTags([...temp]);
        }


        if (isLinked === true || isLinked === "link") {

          if (temp[index].offset == temp[childId].offset) {
            displayNotification("error", "Invalid Id!")
            return
          }

          let tes = temp[index].children
          if (temp[index].children == undefined || temp[index].children == null || temp[index].children.length <= 0) {
            temp[index].children = [];
          }
          if (!temp[childId]) {
            displayNotification("error", "No Child Found With This Id [C]")
            return
          }
          temp[index].children.push(temp[childId]);
          temp.splice(childId, 1);
          setTags([...temp])
          displayNotification("success", "successfullyAdd child [C]")

        }
      }
      else {
        if (isLinked === true || isLinked === "link") {
          const parentid = temp[index].id

          if (temp[index].id === temp[childId].id) {
            displayNotification("error", "Invalid Id!")
            return
          }

          const ret = await sendAPIRequest(`/api/html-contents/add-child`, "POST", {
            content_id: parentid,
            standard_content: temp[childId].standard_content,
            class_name: temp[childId].class_name,
            is_ignore: temp[childId].is_ignore,
            offset: temp[childId].offset,
            tag_name: temp[childId].tag_name,
            childId: temp[childId].id,
            parent_id: temp[index].parent_id
          });
          const res = await ret.json();
          if (res.success && res.status === 200) {
            displayNotification("success", "Successfully marked styles [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to style content [B]");
          }


        }
        if (!isLinked) {


          if (temp[index].id == temp[index].html_contents[childId].id) {
            displayNotification("error", "Invalid Id!")
            return
          }
          if (!temp[index]?.html_contents[childId]) {
            displayNotification("error", "No Child Found at the Specified Index!");
            return;
          }
          let child = temp[index].html_contents[childId].id
          const ret = await sendAPIRequest(`/api/html-contents/unlink-child`, "POST", {
            childId: child,
            text_type: null,
            standard_content: temp[index].html_contents[childId].html,
            document_id: id,
            offset: temp[index].html_contents[childId].offset,
            tag_name: temp[index].html_contents[childId].tag_name,
            class_name: temp[index].html_contents[childId].class_name,
            parent_id: temp[index]?.parent_id
          });

          const res = await ret.json();
          if (res.success && res.status === 200) {
            await loadHtmlData()
            displayNotification("success", "Successfully marked styles [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to style content [B]");
          }

          return

        }

      }


    } catch (error) {

      console.log(error)
      displayNotification("error", "Failed Linked Operation [C] Try Again")
    }
  };

  const handleManualIgnore = async (elementId, level) => {
    try {
      if (window.confirm('Are You Sure To Ignore?')) {
      let temp = [...tags]
      if (!is_cracked) {
        if (level.isChild) {
          temp[level.p].children[elementId].is_ignore = true
          displayNotification("success", "Successfully ignored [C]")
          setTags([...temp])
        }
        if (!level.isChild) {

          let updatedTags = [...temp];
          updatedTags.splice(elementId, 1);
          setTags(updatedTags);
          displayNotification("success", "Successfully ignored [P]")
          // setTags([...temp])
        }
      }
      else {
        if (level.isChild) {
          const element = temp[level.p]?.html_contents[elementId].id

          const ret = await sendAPIRequest(`/api/html-contents/ignore`, "POST", {
            isChild: true,
            id: element,
            is_ignore: true
          });
          const res = await ret.json();
          if (res.success && res.status === 200) {
            await loadHtmlData()
            displayNotification("success", "Successfully Ignored  [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to style content [B]");
          }

        }
        if (!level.isChild) {
          let element = temp[elementId].id
          const ret = await sendAPIRequest(`/api/html-contents/ignore`, "POST", {
            isChild: false,
            id: element,
            is_ignore: 1
          });
          const res = await ret.json();
          if (res.success && res.status === 200) {
            await loadHtmlData()
            displayNotification("success", "Successfully Ignored  [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to style content [B]");
          }

        }
      }
    }
    } catch (error) {
      console.log("error", error)
      displayNotification("error", "Failed ignored [P]")
    }
  }

  const handleEdit = async (elementId, level, content) => {
    try {

      let temp = [...tags]
      if (is_cracked) {
        if (!level.isChild) {
          let ids = temp[elementId].id
          let ret = await sendAPIRequest(`/api/contents/${ids}`, "PUT", {
            standard_content: content
          });
          const res = await ret.json();
          if (res.success && res.status === 200) {
            displayNotification("success", "Successfully updated content [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to update content [B]");
          }
        }
        else {
          let ids = temp[level.p].html_contents[elementId].id
          let ret = await sendAPIRequest(`/api/html/${ids}`, "PUT", {
            content: content
          });
          const res = await ret.json();
          if (res.success && res.status === 200) {
            displayNotification("success", "Successfully updated child content [B]");
            await loadHtmlData()
          } else {
            displayNotification("error", "Failed to update child content [B]");
          }

        }

      }
      else {
        if (level.isChild) {
          temp[level.p].children[elementId].html = content
          setEditValue("")
          displayNotification("success", "Successfully Edit [C]")
          setTags([...temp])
        }
        else {
          temp[elementId].html = content
          setEditValue("")
          displayNotification("success", "Successfully Edited [P]")
          setTags([...temp])
        }
      }
    } catch (error) {
      console.log("error", error)
      displayNotification("error", "Failed  [P]")
    }
  }
  const handleSetMultipleImages = (isMultipleImages, index = 0, imageTag) => {
    if (isMultipleImages) {
      if (selectImage.value === '') {
        displayNotification("error", "Please Select Image First To Set")
        return
      }
      setImagesIds(prev => [...prev, selectImage.value])
      let UpdatedSrc = imageTag.replace(/src="[^"]*"/, `src="${getAPIURL()}/api/document-images/get-image/${selectImage?.value}"`);
      let newMarkedString = markedString;
      var imgMarkerPattern = new RegExp("{IMG_MARKER_" + index + "}", "g");
      let newString = newMarkedString.replace(imgMarkerPattern, UpdatedSrc);
      setMarkedString(newString)
      displayNotification("success", "Successfully Set Image  [C]")
      setSelectedImage({
        value: "",
        label: ""
      })
      return UpdatedSrc
    }
    else {
      return false
    }
  }

  const handleImageSet = async (isMultiple) => {
    try {

      let temp = [...tags];
      let updatedSrc = '';
      const regex = /src="([^"]*)"/;
      if (imageContent === "") {
        return
      }
      const containsImageTag = /<img[^>]*>/i.test(imageContent);
      if (containsImageTag) {
        if (!is_cracked) {
          if (isMultiple) {
            const markerPattern = /\{IMG_MARKER_\d+\}/g;
            const markerExists = markerPattern.test(markedString);
            if (markerExists) {
              displayNotification("error", "Please Complete Marked Before Update [P]")
              return
            }

            if (selectedImageInfo.child) {
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].html = markedString
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].isImage = true
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].imageIds = imagesIds
              setEditValue("")
              setCheckModal(false)
              setSelectedImage({
                child: false,
                parentIndex: null,
                elementIndex: null
              })
              let filterImages = documentImages.filter(item => !imagesIds.includes(item.id));
              setDocumentImages(filterImages)
              setSelectedImage({
                value: '',
                label: ''
              })
              setImageContent('')
              setCheckModal(false)
              displayNotification("success", "Successfully Set Image  [C]")
              setMarkedString('')
              setImagesIds([])
              // setIma
              setTags([...temp])
              return true
            }
            else {
              temp[selectedImageInfo.elementIndex].html = markedString
              temp[selectedImageInfo.elementIndex].isImage = true
              temp[selectedImageInfo.elementIndex].imageIds = imagesIds
              setEditValue("")
              let filterImages = documentImages.filter(item => !imagesIds.includes(item.id));
              setDocumentImages(filterImages)
              setCheckModal(false)
              setSelectedImage({
                child: false,
                parentIndex: null,
                elementIndex: null
              })
              setSelectedImage({
                value: '',
                label: ''
              })
              setImagesIds([])
              setImageContent('')
              setCheckModal(false)
              displayNotification("success", "Successfully Edited [P]")
              setTags([...temp])
              return true
            }
          }
          else {
            if (selectImage.value === "") {
              displayNotification("error", "Please Select image first")
              return
            }
            let modifiedContent = imageContent.replace(regex, `src="${getAPIURL()}/api/document-images/get-image/${selectImage?.value}"`);
            if (selectedImageInfo.child) {
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].html = modifiedContent
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].isImage = true
              temp[selectedImageInfo.parentIndex].children[selectedImageInfo.elementIndex].imageId = selectImage.value
              setEditValue("")
              setCheckModal(false)
              setSelectedImage({
                value: "",
                label: ""
              })
              setSelectedImageInfo({
                child: false,
                parentIndex: null,
                elementIndex: null
              })
              let filterImages = documentImages?.filter((itm) => itm.id !== selectImage?.value)
              setDocumentImages(filterImages)
              setImageContent('')
              setCheckModal(false)
              displayNotification("success", "Successfully Set Image  [C]")
              setTags([...temp])
              return true
            }
            else {
              temp[selectedImageInfo.elementIndex].html = modifiedContent
              temp[selectedImageInfo.elementIndex].isImage = true
              temp[selectedImageInfo.elementIndex].imageId = selectImage.value
              setEditValue("")
              let filterImages = documentImages?.filter((itm) => itm.id !== selectImage?.value)
              setDocumentImages(filterImages)
              setSelectedImage({
                value: '',
                label: ''
              })
              setCheckModal(false)
              setSelectedImageInfo({
                child: false,
                parentIndex: null,
                elementIndex: null
              })
              setSelectedImage({
                value: '',
                label: ''
              })
              setImageContent('')
              setCheckModal(false)
              displayNotification("success", "Successfully Edited [P]")
              setTags([...temp])
              return true
            }
          }
        }

        else {
          if (isMultiple) {
            const markerPattern = /\{IMG_MARKER_\d+\}/g;
            const markerExists = markerPattern.test(markedString);
            if (markerExists) {
              displayNotification("error", "Please Complete Marked Before Update [P]")
              return
            }
            if (!selectedImageInfo.child) {
              let ids = temp[selectedImageInfo.elementIndex].id
              temp[selectedImageInfo.elementIndex].isImage = true
              let ret = await sendAPIRequest(`/api/contents/${ids}?isimage=true${selectImage.value !== '' ? `&id=${selectImage.value}` : ''}&multiple=true`, "PUT", {
                standard_content: markedString,
                ids: imagesIds
              });
              const res = await ret.json();
              if (res.success && res.status === 200) {
                displayNotification("success", "Successfully updated content [B]");
                setCheckModal(false)
                setSelectedImageInfo({
                  child: false,
                  parentIndex: null,
                  elementIndex: null
                })
                setSelectedImage({
                  value: '',
                  label: ''
                })
                setImagesIds([])
                setMarkedString('')
                await loadHtmlData()
                await loadImages()

              } else {
                displayNotification("error", "Failed to update content [B]");
              }
            }
            else {
              let ids = temp[selectedImageInfo.parentIndex].html_contents[selectedImageInfo.elementIndex].id
              let ret = await sendAPIRequest(`/api/html/${ids}?isimage=true${selectImage.value !== '' ? `&id=${selectImage.value}` : ''}&multiple=true`, "PUT", {
                content: markedString,
                ids: imagesIds
              });
              const res = await ret.json();
              if (res.success && res.status === 200) {
                displayNotification("success", "Successfully updated child content [B]");
                setCheckModal(false)
                setSelectedImageInfo({
                  child: false,
                  parentIndex: null,
                  elementIndex: null
                })
                setSelectedImage({
                  value: '',
                  label: ''
                })
                await loadHtmlData()
                await loadImages()
              } else {
                displayNotification("error", "Failed to update child content [B]");
              }
            }
          }
          else {
            if (selectImage.value === "") {
              displayNotification("error", "Please Select image first")
              return
            }
            let modifiedContent = imageContent.replace(regex, `src="${getAPIURL()}/api/document-images/get-image/${selectImage?.value}"`);
            if (!selectedImageInfo.child) {
              let ids = temp[selectedImageInfo.elementIndex].id
              temp[selectedImageInfo.elementIndex].isImage = true
              let ret = await sendAPIRequest(`/api/contents/${ids}?isimage=true${selectImage.value !== '' ? `&id=${selectImage.value}` : ''}`, "PUT", {
                standard_content: modifiedContent,
              });
              const res = await ret.json();
              if (res.success && res.status === 200) {
                displayNotification("success", "Successfully updated content [B]");
                setSelectedImageInfo({
                  child: false,
                  parentIndex: null,
                  elementIndex: null
                })
                setSelectedImage({
                  value: '',
                  label: ''
                })
                setImageContent('')
                await loadHtmlData()
                await loadImages()

              } else {
                displayNotification("error", "Failed to update content [B]");
              }
            }
            else {
              let ids = temp[selectedImageInfo.parentIndex].html_contents[selectedImageInfo.elementIndex].id
              let ret = await sendAPIRequest(`/api/html/${ids}?isimage=true${selectImage.value !== '' ? `&id=${selectImage.value}` : ''}`, "PUT", {
                content: modifiedContent
              });
              const res = await ret.json();
              if (res.success && res.status === 200) {
                displayNotification("success", "Successfully updated child content [B]");
                setCheckModal(false)
                setImageContent('')
                setSelectedImageInfo({
                  child: false,
                  parentIndex: null,
                  elementIndex: null
                })
                setSelectedImage({
                  value: '',
                  label: ''
                })
                await loadHtmlData()
                await loadImages()
              } else {
                displayNotification("error", "Failed to update child content [B]");
              }
            }
          }
        }
      }
      else {
        displayNotification("error", "Does have image tag");
      }
    } catch (e) {
      console.log(e)
      displayNotification("error", "Failed to mark [F]");
    }
  };

  const handleRevert = async (elementId) => {
    if (window.confirm('This action will Revert Marking Content. Are you sure?')) {
      try {
        let temp = [...tags]

        if (!is_cracked) {
          temp[elementId].text_type = null
          let check = temp[elementId].children ? true : false
          if (check) {
            let removedChildren = temp[elementId].children;
            let data = []
            let final = removedChildren?.map((r, i) => {
              data.push(r)
              return
            })
            delete temp[elementId].children;
            // temp.push(final);
            data?.map((x, i) => {
              temp.push(x)
            })
            temp.sort((a, b) => {
              const idA = a.offset - 1;
              const idB = b.offset - 1;

              // Comparing the adjusted ids
              if (idA < idB) {
                return -1;
              }
              if (idA > idB) {
                return 1;
              }
              return 0;
            });
          }

          displayNotification("success", "Successfully Reverted [P]")
          setTags([...temp])

        }
        else {
          let id = temp[elementId].id
          const ret = await sendAPIRequest(`/api/html-contents/revert`, "POST", {
            id,
            data: temp[elementId]
          });
          let res = await ret.json()
          if (res.success && res.status === 200) {
            displayNotification("success", "Successfully marked styles [B]");
          } else {
            displayNotification("error", "Failed to style content [B]");
          }
          await loadHtmlData()
        }

      } catch (error) {
        console.log("error", error)
        displayNotification("error", "Failed ignored [P]")
      }
    }

  }


  const loadImages = async () => {
    setImagesLoading(true);
    try {

      const ret = await sendAPIRequest(
        `/api/document-images/${getAllImages ? 'get-all-images' : 'get-images'}`
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setDocumentImages(res.data);
      }
    } catch (e) {
      console.log({ e });
    }
    setImagesLoading(false);
  }

  useEffect(() => {
    loadImages()
  }, [getAllImages])


  const handleCloseTextTypeModal = () => {
    setTextTypeDetail({
      id: '',
      text_type: ''
    })
    setTextTypeModal(false);
  }
  const handleChangeTextType = (index) => {
    let temp = [...tags];
    let id = temp[index].id;
    setTextTypeDetail({
      id: id,
      text_type: temp[index].text_type
    })
    setTextTypeModal(true);
  }

  return (
    <>
      <EditModal ref={editRef} />
      <SetImageSrcModel
        image={selectImage}
        setSelectedImage={setSelectedImage}
        documentImages={documentImages}
        setDocumentImages={setDocumentImages}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
        loading={imagesLoading}
        imageOptions={imageOptions}
        checkModal={checkModal}
        setCheckModal={setCheckModal}
        handleImageSet={handleImageSet}
        imageContent={imageContent}
        setImageContent={setImageContent}
        setSelectedImageInfo={setSelectedImageInfo}
        markedString={markedString}
        setMarkedString={setMarkedString}
        handleSetMultipleImages={handleSetMultipleImages}
        setImagesIds={setImagesIds}
        getAllImages={getAllImages}
        setGetAllImages={setGetAllImages}
      />
      <SplitModal ref={splitRef} />
      <div className="container my-2">
        <div className="row header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
          <div className="col-md-8 d-flex justify-content-between">
            <div className="d-flex justify-content-between">
              <h1 className="col-md-4 card-title1 font-custom-2 lh-1">
                {t("117", "Data Manual Mapping")}
              </h1>
              <TextFieldCustom
                className="col-md-4 ms-auto my-auto"
                onChange={(e) => {
                  return
                  // searchText(e.target.value);
                }}
                required={true}
                error={true}
                label={t("13", "Search")}
                fullWidth
                size="small"
              />
              <p className="col-md-4 ms-auto my-auto">
                No. of matched records: {matchedCount}
              </p>
            </div>
          </div>
          <div className="col-md-4 header-right-items ms-auto d-flex align-items-center">
            <div className="ms-auto">
              <button
                className="btn btn--primary font-custom fs-6 ms-1"
                onClick={goBack}
              >
                {t("162", "Back")}
              </button>
              {currentPage === "data-mapping" && (
                <>
                  <IgnoreDataModal
                    data={ignoreData}
                    reloadData={(content, selectedIDs) =>
                      restoreIgnoreData(content, selectedIDs)
                    }
                    children={(openModel) => (
                      <button
                        disabled={true}
                        type="button"
                        className="btn btn--primary font-custom fs-6 ms-1"
                        onClick={() => openModel()}
                      >
                        
                        {t("179", "Ignored Data")}
                      </button>
                    )}
                  />
                  {!isMigrated && (
                    <button
                      disabled={true}
                      className="btn btn--primary font-custom fs-6 ms-1"
                      onClick={storeData}
                    // disabled={isLoading.save_data}
                    >
                      {isLoading.save_data ? (
                        <Spinner message="Saving, Please Wait..." />
                      ) : (
                        t("180", "Save")
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
          {isLoading.load_data ? (
            <div className="my-2">
              <Spinner message="Loading Data..." />
            </div>
          ) : (
            <>
              {
                tags && tags.length > 0 ? (
                  tags?.map((d, index) => {
                    return (
                      <div className="card-body pt-4 pb-0 px-5" key={`p${index + "." + 1}`}>
                        <div className=" ">
                          {
                            d?.text_type ===
                              LIST_DETAIL_ELEMENTS.text_types.manual_paragraph ||
                              d?.text_type ===
                              LIST_DETAIL_ELEMENTS.text_types.manual_appendex_heading ||
                              d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ||
                              d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_footer ||
                              d.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading ||

                              d.role === null ?
                              (
                                <>
                                  {
                                    d.is_ignore != true &&
                                    <div
                                      className="align-items-center d-flex w-100"
                                      key={d.id}
                                    >
                                      <div className="bg-light-beige p-4 border border-dark w-100">
                                        <span style={{ color: "blue", fontWeight: "bolder" }}
                                          className=" p-2"
                                        >
                                          {d.text_type === LIST_DETAIL_ELEMENTS.styles_categories.code_editing_date_text ? "Code Aditing Date" : d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_appendex_heading ? "AppendexHeading" :
                                            d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_footer ? "Footer" :
                                              d.text_type === LIST_DETAIL_ELEMENTS.heading_types.general_heading ? "General Heading" :
                                                "Paragraph"}</span>
                                        <div className="d-flex ">

                                          <p className="my-0">

                                            <HtmlRenderer
                                              tag={d.tag_name ?? "span"}
                                              htmlContent={d.html ? d.html : d.standard_content}
                                            />

                                            <b style={{ color: "blue" }}>#ID:{index}</b>
                                          </p>
                                          {currentPage === "data-mapping" && (
                                            <ManualDropDownMenu
                                              editRef={editRef}
                                              splitRef={splitRef}
                                              setImageRef={setImageRef}
                                              setContentNumber={setContentNumber}
                                              handleMarking={handleMarking}
                                              handleMarkAsParagraph={handleMarkAsParagraph}
                                              handleMarkAsParaph={handleMarkAsParaph}
                                              handleMarkAsHeading={handleMarkAsHeading}
                                              handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                              handleManualIgnore={handleManualIgnore}
                                              handleEdit={handleEdit}
                                              handleImageSet={handleImageSet}
                                              level={
                                                {
                                                  isChild: false,
                                                  p: null
                                                }
                                              }
                                              updateContent={updateContent}
                                              ignoreContent={ignoreContent}
                                              splitText={splitText}
                                              index={index}
                                              index2={null}
                                              d={d}
                                              handleMarkAsFooter={handleMarkAsFooter}
                                              handleMarkAsRefernce={handleMarkAsRefernce}
                                              handleMarkAsAppendex={handleMarkAsAppendex}
                                              handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                              handleMarkASApendexHeading={handleMarkASApendexHeading}
                                              handleMarkFutherReadig={handleMarkFutherReadig}
                                              handleMarkOnlineResources={handleMarkOnlineResources}
                                              handleRevert={handleRevert}
                                              handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                              setCheckModal={setCheckModal}
                                              setImageContent={setImageContent}
                                              setSelectedImageInfo={setSelectedImageInfo}
                                              handleChangeTextType={handleChangeTextType}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <span className="d-flex">
                                        <i
                                          className="fa-solid fa-caret-up m-1 fs-2 cursor-pointer"
                                          onClick={() =>
                                            updateOffset(index, null, "increase")
                                          }
                                        ></i>
                                        <i
                                          className="fa-solid fa-caret-down m-1 fs-2 cursor-pointer"
                                          onClick={() =>
                                            updateOffset(index, null, "decrease")
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                  }</>
                              ) :
                              d?.text_type ===
                                LIST_DETAIL_ELEMENTS.text_types.manual_footer ?

                                (
                                  <>
                                    {
                                      d.is_ignore != true &&

                                      <div
                                        className="align-items-center d-flex w-100"
                                        key={d.id}
                                      >
                                        <div className="bg-light-beige p-4 border border-dark w-100">
                                          <span style={{ color: "blue", fontWeight: "bolder" }}
                                            className=" p-2"
                                          >Footer</span>
                                          <div className="d-flex ">

                                            <p className="my-0">
                                              <HtmlRenderer
                                                tag={"span"}
                                                htmlContent={d.html ? d.html : d.standard_content}
                                              />

                                              <b style={{ color: "blue" }}>#ID:{index}</b>
                                            </p>
                                            {currentPage === "data-mapping" && (
                                              <ManualDropDownMenu
                                                editRef={editRef}
                                                setImageRef={setImageRef}
                                                splitRef={splitRef}
                                                setContentNumber={setContentNumber}
                                                handleMarking={handleMarking}
                                                handleMarkAsParagraph={handleMarkAsParagraph}
                                                handleMarkAsParaph={handleMarkAsParaph}
                                                handleMarkAsHeading={handleMarkAsHeading}
                                                handleManualIgnore={handleManualIgnore}
                                                handleEdit={handleEdit}
                                                handleImageSet={handleImageSet}
                                                level={
                                                  {
                                                    isChild: false,
                                                    p: null
                                                  }
                                                }
                                                updateContent={updateContent}
                                                ignoreContent={ignoreContent}
                                                splitText={splitText}
                                                index={index}
                                                index2={null}
                                                d={d}
                                                handleMarkAsFooter={handleMarkAsFooter}
                                                handleMarkAsRefernce={handleMarkAsRefernce}
                                                handleMarkAsAppendex={handleMarkAsAppendex}
                                                handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                handleRevert={handleRevert}
                                                handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                setCheckModal={setCheckModal}
                                                setImageContent={setImageContent}
                                                setSelectedImageInfo={setSelectedImageInfo}
                                                handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                handleChangeTextType={handleChangeTextType}
                                              />
                                            )}

                                          </div>
                                        </div>
                                        <span className="d-flex">
                                          <i
                                            className="fa-solid fa-caret-up m-1 fs-2 cursor-pointer"
                                            onClick={() =>
                                              updateOffset(index, null, "increase")
                                            }
                                          ></i>
                                          <i
                                            className="fa-solid fa-caret-down m-1 fs-2 cursor-pointer"
                                            onClick={() =>
                                              updateOffset(index, null, "decrease")
                                            }
                                          ></i>
                                        </span>
                                      </div>
                                    }</>
                                ) :

                                d.text_type ===
                                  LIST_DETAIL_ELEMENTS.text_types.manual_heading ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.text_types.glossary_term ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.text_types.manual_online_resource ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.recommendation_types
                                    .safe_commodities ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.recommendation_types
                                    .recommendations_for_importations ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.recommendation_types
                                    .inactivation_procedures ||
                                  d.text_type ===
                                  LIST_DETAIL_ELEMENTS.heading_types
                                    .general_heading ? (
                                  <>
                                    {
                                      d.is_ignore != true &&
                                      <>
                                        <div className="row mb-0 py-3 bg-light-orange border-light-orange g-0">
                                          <div className="col-md-6">
                                            <span style={{ color: "blue", fontWeight: "bolder" }}
                                              className=" p-2"
                                            >
                                              {d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_Further_reading ? "Further Reacding" :
                                                d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_online_resource ? "Online Resources" :
                                                  d.text_type === LIST_DETAIL_ELEMENTS.text_types.glossary_term ? "Glossary Term" :
                                                    "Heading"
                                              }

                                            </span>
                                            <h2 className="d-flex ms-2">
                                              <HtmlRenderer
                                                tag={"span"}
                                                htmlContent={d.html ? d.html : d.standard_content}
                                              />
                                              <b style={{ color: "blue" }}>#ID:{index}</b>
                                              {currentPage === "data-mapping" && (
                                                <ManualDropDownMenu
                                                  editRef={editRef}
                                                  splitRef={splitRef}
                                                  setImageRef={setImageRef}
                                                  setContentNumber={setContentNumber}
                                                  handleMarking={handleMarking}
                                                  handleMarkAsParagraph={
                                                    handleMarkAsParagraph
                                                  }
                                                  handleMarkAsParaph={handleMarkAsParaph}
                                                  handleMarkAsHeading={handleMarkAsHeading}
                                                  updateContent={updateContent}
                                                  ignoreContent={ignoreContent}
                                                  splitText={splitText}
                                                  index={index}
                                                  index2={null}
                                                  handleEdit={handleEdit}
                                                  handleImageSet={handleImageSet}
                                                  handleManualIgnore={handleManualIgnore}
                                                  level={
                                                    {
                                                      isChild: false,
                                                      p: null
                                                    }
                                                  }
                                                  d={d}
                                                  handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                  handleMarkAsFooter={handleMarkAsFooter}
                                                  handleMarkAsRefernce={handleMarkAsRefernce}
                                                  handleMarkAsAppendex={handleMarkAsAppendex}
                                                  handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                  handleRevert={handleRevert}
                                                  handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                  handleMarkFutherReadig={handleMarkFutherReadig}
                                                  handleMarkOnlineResources={handleMarkOnlineResources}
                                                  handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                  setCheckModal={setCheckModal}
                                                  setImageContent={setImageContent}
                                                  setSelectedImageInfo={setSelectedImageInfo}
                                                  handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                  handleChangeTextType={handleChangeTextType}
                                                />
                                              )}
                                            </h2>

                                          </div>
                                          {currentPage === "data-mapping" && (
                                            <div className="col-md-6 text-end">
                                              <ManualParentChildMapping
                                                handleRadioButtonChangeState={
                                                  handleRadioButtonChangeState
                                                }
                                                linkChildWithParent={linkChildWithParent}
                                                handleManualChildLink={handleManualChildLink}
                                                index={index}
                                                index2={null}
                                                selectedValue={selectedValue}
                                              />
                                            </div>
                                          )}
                                        </div>

                                        <>
                                          {
                                            d.children && d.children.length > 0 ? (
                                              d.children.map((val, key) => (
                                                <>
                                                  {val.is_ignore != true && (



                                                    <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                      <h4>{index.key}</h4>
                                                      <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                        <p className="my-0">
                                                          <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                          {currentPage === "data-mapping" && (
                                                            <ManualDropDownMenu
                                                              editRef={editRef}
                                                              setImageRef={setImageRef}
                                                              splitRef={splitRef}
                                                              setContentNumber={setContentNumber}
                                                              handleMarking={handleMarking}
                                                              handleMarkAsParagraph={
                                                                handleMarkAsParagraph
                                                              }
                                                              handleMarkAsParaph={handleMarkAsParaph}
                                                              handleMarkAsHeading={handleMarkAsHeading}
                                                              updateContent={updateContent}
                                                              ignoreContent={ignoreContent}
                                                              splitText={splitText}
                                                              index={key}
                                                              index2={null}
                                                              handleEdit={handleEdit}
                                                              handleImageSet={handleImageSet}
                                                              handleManualIgnore={handleManualIgnore}
                                                              level={
                                                                {
                                                                  isChild: true,
                                                                  p: index
                                                                }
                                                              }
                                                              d={val}
                                                              handleMarkAsFooter={handleMarkAsFooter}
                                                              handleMarkAsRefernce={handleMarkAsRefernce}
                                                              handleMarkAsAppendex={handleMarkAsAppendex}
                                                              handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                              handleRevert={handleRevert}
                                                              handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                              handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                              handleMarkFutherReadig={handleMarkFutherReadig}
                                                              handleMarkOnlineResources={handleMarkOnlineResources}
                                                              handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                              setCheckModal={setCheckModal}
                                                              setImageContent={setImageContent}
                                                              setSelectedImageInfo={setSelectedImageInfo}
                                                              handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                              handleChangeTextType={handleChangeTextType}
                                                            />
                                                          )}
                                                          <b style={{ color: "blue" }}>#ID : {key}</b>
                                                        </p>
                                                        <span className="d-flex">
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ))
                                            ) : d.html_contents && d.html_contents.length > 0 ? (
                                              d.html_contents.map((val, key) => (
                                                <>
                                                  {
                                                    val.is_ignore != true && (
                                                      <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                        <h4>{index.key}</h4>
                                                        <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                          <p className="my-0">
                                                            <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                            {currentPage === "data-mapping" && (
                                                              <ManualDropDownMenu
                                                                editRef={editRef}
                                                                splitRef={splitRef}
                                                                setImageRef={setImageRef}
                                                                setContentNumber={setContentNumber}
                                                                handleMarking={handleMarking}
                                                                handleMarkAsParagraph={
                                                                  handleMarkAsParagraph
                                                                }
                                                                handleMarkAsParaph={handleMarkAsParaph}
                                                                handleMarkAsHeading={handleMarkAsHeading}
                                                                updateContent={updateContent}
                                                                ignoreContent={ignoreContent}
                                                                splitText={splitText}
                                                                index={key}
                                                                index2={null}
                                                                handleEdit={handleEdit}
                                                                handleImageSet={handleImageSet}
                                                                handleManualIgnore={handleManualIgnore}
                                                                level={
                                                                  {
                                                                    isChild: true,
                                                                    p: index
                                                                  }
                                                                }
                                                                d={val}
                                                                handleMarkAsFooter={handleMarkAsFooter}
                                                                handleMarkAsRefernce={handleMarkAsRefernce}
                                                                handleMarkAsAppendex={handleMarkAsAppendex}
                                                                handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                handleRevert={handleRevert}
                                                                handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                handleMarkFutherReadig={handleMarkFutherReadig}
                                                                handleMarkOnlineResources={handleMarkOnlineResources}
                                                                handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                setCheckModal={setCheckModal}
                                                                setImageContent={setImageContent}
                                                                setSelectedImageInfo={setSelectedImageInfo}
                                                                handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                handleChangeTextType={handleChangeTextType}
                                                              />
                                                            )}
                                                            <b style={{ color: "blue" }}>#ID : {key}</b>
                                                          </p>            <span className="d-flex">
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                </>
                                              ))
                                            ) : (
                                              <></>
                                            )
                                          }

                                        </>

                                      </>


                                    }
                                  </>
                                ) :
                                  d.text_type ===
                                    LIST_DETAIL_ELEMENTS.text_types.manual_reference ? (
                                    <>
                                      {
                                        d.is_ignore != true &&
                                        <>
                                          <div className="row mb-0 py-3 bg-light-orange border-light-orange g-0">
                                            <div className="col-md-6">
                                              <span style={{ color: "blue", fontWeight: "bolder" }}
                                                className=" p-2"
                                              >Reference</span>
                                              <h2 className="d-flex ms-2">


                                                <HtmlRenderer
                                                  tag={"span"}
                                                  htmlContent={d.html ? d.html : d.standard_content}
                                                />
                                                <b style={{ color: "blue" }}>#ID:{index}</b>

                                                {currentPage === "data-mapping" && (
                                                  <ManualDropDownMenu
                                                    editRef={editRef}
                                                    splitRef={splitRef}
                                                    setImageRef={setImageRef}
                                                    setContentNumber={setContentNumber}
                                                    handleMarking={handleMarking}
                                                    handleMarkAsParagraph={
                                                      handleMarkAsParagraph
                                                    }
                                                    handleMarkAsParaph={handleMarkAsParaph}
                                                    handleMarkAsHeading={handleMarkAsHeading}
                                                    updateContent={updateContent}
                                                    ignoreContent={ignoreContent}
                                                    splitText={splitText}
                                                    index={index}
                                                    index2={null}
                                                    handleEdit={handleEdit}
                                                    handleImageSet={handleImageSet}
                                                    handleManualIgnore={handleManualIgnore}
                                                    level={
                                                      {
                                                        isChild: false,
                                                        p: null
                                                      }
                                                    }
                                                    d={d}
                                                    handleMarkAsFooter={handleMarkAsFooter}
                                                    handleMarkAsRefernce={handleMarkAsRefernce}
                                                    handleMarkAsAppendex={handleMarkAsAppendex}
                                                    handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                    handleRevert={handleRevert}
                                                    handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                    handleMarkFutherReadig={handleMarkFutherReadig}
                                                    handleMarkOnlineResources={handleMarkOnlineResources}
                                                    handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                    setCheckModal={setCheckModal}
                                                    setImageContent={setImageContent}
                                                    setSelectedImageInfo={setSelectedImageInfo}
                                                    handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                    handleChangeTextType={handleChangeTextType}
                                                  />
                                                )}

                                              </h2>

                                            </div>
                                            {currentPage === "data-mapping" && (
                                              <div className="col-md-6 text-end">
                                                <ManualParentChildMapping
                                                  handleRadioButtonChangeState={
                                                    handleRadioButtonChangeState
                                                  }
                                                  linkChildWithParent={linkChildWithParent}
                                                  handleManualChildLink={handleManualChildLink}
                                                  index={index}
                                                  index2={null}
                                                  selectedValue={selectedValue}
                                                />
                                              </div>
                                            )}
                                          </div>

                                          <>
                                            {
                                              d.children && d.children.length > 0 ? (
                                                d.children.map((val, key) => (
                                                  <>
                                                    {
                                                      val.is_ignore != true && (
                                                        <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                          <h4>{index.key}</h4>
                                                          <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                            <p className="my-0">
                                                              <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                              {currentPage === "data-mapping" && (
                                                                <ManualDropDownMenu
                                                                  editRef={editRef}
                                                                  splitRef={splitRef}
                                                                  setImageRef={setImageRef}
                                                                  setContentNumber={setContentNumber}
                                                                  handleMarking={handleMarking}
                                                                  handleMarkAsParagraph={
                                                                    handleMarkAsParagraph
                                                                  }
                                                                  handleMarkAsParaph={handleMarkAsParaph}
                                                                  handleMarkAsHeading={handleMarkAsHeading}
                                                                  updateContent={updateContent}
                                                                  ignoreContent={ignoreContent}
                                                                  splitText={splitText}
                                                                  index={key}
                                                                  index2={null}
                                                                  handleEdit={handleEdit}
                                                                  handleImageSet={handleImageSet}
                                                                  handleManualIgnore={handleManualIgnore}
                                                                  level={
                                                                    {
                                                                      isChild: true,
                                                                      p: index
                                                                    }
                                                                  }
                                                                  d={val}
                                                                  handleMarkAsFooter={handleMarkAsFooter}
                                                                  handleMarkAsRefernce={handleMarkAsRefernce}
                                                                  handleMarkAsAppendex={handleMarkAsAppendex}
                                                                  handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                  handleRevert={handleRevert}
                                                                  handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                  handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                  handleMarkFutherReadig={handleMarkFutherReadig}
                                                                  handleMarkOnlineResources={handleMarkOnlineResources}
                                                                  handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                  setCheckModal={setCheckModal}
                                                                  setImageContent={setImageContent}
                                                                  setSelectedImageInfo={setSelectedImageInfo}
                                                                  handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                  handleChangeTextType={handleChangeTextType}
                                                                />
                                                              )}
                                                              <b style={{ color: "blue" }}>#ID : {key}</b>
                                                            </p>
                                                            <span className="d-flex">
                                                              {/* Your caret icons */}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </>
                                                ))
                                              ) : d.html_contents && d.html_contents.length > 0 ? (
                                                d.html_contents.map((val, key) => (
                                                  <>
                                                    {
                                                      val.is_ignore != true && (
                                                        <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                          <h4>{index.key}</h4>
                                                          <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                            <p className="my-0">
                                                              <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                              {currentPage === "data-mapping" && (
                                                                <ManualDropDownMenu
                                                                  editRef={editRef}
                                                                  splitRef={splitRef}
                                                                  setImageRef={setImageRef}
                                                                  setContentNumber={setContentNumber}
                                                                  handleMarking={handleMarking}
                                                                  handleMarkAsParagraph={
                                                                    handleMarkAsParagraph
                                                                  }
                                                                  handleMarkAsParaph={handleMarkAsParaph}
                                                                  handleMarkAsHeading={handleMarkAsHeading}
                                                                  updateContent={updateContent}
                                                                  ignoreContent={ignoreContent}
                                                                  splitText={splitText}
                                                                  index={key}
                                                                  index2={null}
                                                                  handleEdit={handleEdit}
                                                                  handleImageSet={handleImageSet}
                                                                  handleManualIgnore={handleManualIgnore}
                                                                  level={
                                                                    {
                                                                      isChild: true,
                                                                      p: index
                                                                    }
                                                                  }
                                                                  d={val}
                                                                  handleMarkAsFooter={handleMarkAsFooter}
                                                                  handleMarkAsRefernce={handleMarkAsRefernce}
                                                                  handleMarkAsAppendex={handleMarkAsAppendex}
                                                                  handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                  handleRevert={handleRevert}
                                                                  handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                  handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                  handleMarkFutherReadig={handleMarkFutherReadig}
                                                                  handleMarkOnlineResources={handleMarkOnlineResources}
                                                                  handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                  setCheckModal={setCheckModal}
                                                                  setImageContent={setImageContent}
                                                                  setSelectedImageInfo={setSelectedImageInfo}
                                                                  handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                  handleChangeTextType={handleChangeTextType}
                                                                />
                                                              )}
                                                              <b style={{ color: "blue" }}>#ID : {key}</b>
                                                            </p>
                                                            <span className="d-flex">
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </>
                                                ))
                                              ) : (
                                                <></>
                                              )
                                            }

                                          </>

                                        </>


                                      }
                                    </>
                                  ) :

                                    d.text_type === LIST_DETAIL_ELEMENTS.text_types.manual_biblography ?
                                      (
                                        <>
                                          {
                                            d.is_ignore != true

                                            && <>
                                              <div className="row mb-0 py-3 bg-light-orange border-light-orange g-0">
                                                <div className="col-md-6">
                                                  <span style={{ color: "blue", fontWeight: "bolder" }}
                                                    className=" p-2"
                                                  >Biblography</span>
                                                  <h2 className="d-flex ms-2">


                                                    <HtmlRenderer
                                                      tag={"span"}
                                                      htmlContent={d.html ? d.html : d.standard_content}
                                                    />
                                                    <b style={{ color: "blue" }}>#ID:{index}</b>

                                                    {currentPage === "data-mapping" && (
                                                      <ManualDropDownMenu
                                                        editRef={editRef}
                                                        splitRef={splitRef}
                                                        setImageRef={setImageRef}
                                                        setContentNumber={setContentNumber}
                                                        handleMarking={handleMarking}
                                                        handleMarkAsParagraph={
                                                          handleMarkAsParagraph
                                                        }
                                                        handleMarkAsParaph={handleMarkAsParaph}
                                                        handleMarkAsHeading={handleMarkAsHeading}
                                                        updateContent={updateContent}
                                                        ignoreContent={ignoreContent}
                                                        splitText={splitText}
                                                        index={index}
                                                        index2={null}
                                                        handleEdit={handleEdit}
                                                        handleImageSet={handleImageSet}
                                                        handleManualIgnore={handleManualIgnore}
                                                        level={
                                                          {
                                                            isChild: false,
                                                            p: null
                                                          }
                                                        }
                                                        d={d}
                                                        handleMarkAsFooter={handleMarkAsFooter}
                                                        handleMarkAsRefernce={handleMarkAsRefernce}
                                                        handleMarkAsAppendex={handleMarkAsAppendex}
                                                        handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                        handleRevert={handleRevert}
                                                        handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                        handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                        handleMarkFutherReadig={handleMarkFutherReadig}
                                                        handleMarkOnlineResources={handleMarkOnlineResources}
                                                        handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                        setCheckModal={setCheckModal}
                                                        setImageContent={setImageContent}
                                                        setSelectedImageInfo={setSelectedImageInfo}
                                                        handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                        handleChangeTextType={handleChangeTextType}
                                                      />
                                                    )}

                                                  </h2>

                                                </div>
                                                {currentPage === "data-mapping" && (
                                                  <div className="col-md-6 text-end">
                                                    <ManualParentChildMapping
                                                      handleRadioButtonChangeState={
                                                        handleRadioButtonChangeState
                                                      }
                                                      linkChildWithParent={linkChildWithParent}
                                                      handleManualChildLink={handleManualChildLink}
                                                      index={index}
                                                      index2={null}
                                                      selectedValue={selectedValue}
                                                    />
                                                  </div>
                                                )}
                                              </div>

                                              <>
                                                {
                                                  d.children && d.children.length > 0 ? (
                                                    d.children.map((val, key) => (
                                                      <>
                                                        {val.is_ignore != true && (
                                                          <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                            <h4>{index.key}</h4>
                                                            <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                              <p className="my-0">
                                                                <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                                {currentPage === "data-mapping" && (
                                                                  <ManualDropDownMenu
                                                                    editRef={editRef}
                                                                    splitRef={splitRef}
                                                                    setImageRef={setImageRef}
                                                                    setContentNumber={setContentNumber}
                                                                    handleMarking={handleMarking}
                                                                    handleMarkAsParagraph={
                                                                      handleMarkAsParagraph
                                                                    }
                                                                    handleMarkAsParaph={handleMarkAsParaph}
                                                                    handleMarkAsHeading={handleMarkAsHeading}
                                                                    updateContent={updateContent}
                                                                    ignoreContent={ignoreContent}
                                                                    splitText={splitText}
                                                                    index={key}
                                                                    index2={null}
                                                                    handleEdit={handleEdit}
                                                                    handleImageSet={handleImageSet}
                                                                    handleManualIgnore={handleManualIgnore}
                                                                    level={
                                                                      {
                                                                        isChild: true,
                                                                        p: index
                                                                      }
                                                                    }
                                                                    d={val}
                                                                    handleMarkAsFooter={handleMarkAsFooter}
                                                                    handleMarkAsRefernce={handleMarkAsRefernce}
                                                                    handleMarkAsAppendex={handleMarkAsAppendex}
                                                                    handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                    handleRevert={handleRevert}
                                                                    handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                    handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                    handleMarkFutherReadig={handleMarkFutherReadig}
                                                                    handleMarkOnlineResources={handleMarkOnlineResources}
                                                                    handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                    setCheckModal={setCheckModal}
                                                                    setImageContent={setImageContent}
                                                                    setSelectedImageInfo={setSelectedImageInfo}
                                                                    handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                    handleChangeTextType={handleChangeTextType}
                                                                  />
                                                                )}
                                                                <b style={{ color: "blue" }}>#ID : {key}</b>
                                                              </p>
                                                              <span className="d-flex">
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    ))
                                                  ) : d.html_contents && d.html_contents.length > 0 ? (
                                                    d.html_contents.map((val, key) => (
                                                      <>
                                                        {
                                                          val.is_ignore != true && (
                                                            <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                              <h4>{index.key}</h4>
                                                              <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                                <p className="my-0">
                                                                  <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                                  {currentPage === "data-mapping" && (
                                                                    <ManualDropDownMenu
                                                                      editRef={editRef}
                                                                      splitRef={splitRef}
                                                                      setImageRef={setImageRef}
                                                                      setContentNumber={setContentNumber}
                                                                      handleMarking={handleMarking}
                                                                      handleMarkAsParagraph={
                                                                        handleMarkAsParagraph
                                                                      }
                                                                      handleMarkAsParaph={handleMarkAsParaph}
                                                                      handleMarkAsHeading={handleMarkAsHeading}
                                                                      updateContent={updateContent}
                                                                      ignoreContent={ignoreContent}
                                                                      splitText={splitText}
                                                                      index={key}
                                                                      index2={null}
                                                                      handleEdit={handleEdit}
                                                                      handleImageSet={handleImageSet}
                                                                      handleManualIgnore={handleManualIgnore}
                                                                      level={
                                                                        {
                                                                          isChild: true,
                                                                          p: index
                                                                        }
                                                                      }
                                                                      d={val}
                                                                      handleMarkAsFooter={handleMarkAsFooter}
                                                                      handleMarkAsRefernce={handleMarkAsRefernce}
                                                                      handleMarkAsAppendex={handleMarkAsAppendex}
                                                                      handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                      handleRevert={handleRevert}
                                                                      handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                      handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                      handleMarkFutherReadig={handleMarkFutherReadig}
                                                                      handleMarkOnlineResources={handleMarkOnlineResources}
                                                                      handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                      setCheckModal={setCheckModal}
                                                                      setImageContent={setImageContent}
                                                                      setSelectedImageInfo={setSelectedImageInfo}
                                                                      handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                      handleChangeTextType={handleChangeTextType}
                                                                    />
                                                                  )}
                                                                  <b style={{ color: "blue" }}>#ID : {key}</b>
                                                                </p>
                                                                <span className="d-flex">
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </>
                                                    ))
                                                  ) : (
                                                    <></>
                                                  )
                                                }

                                              </>

                                            </>


                                          }
                                        </>
                                      ) :
                                      d.text_type ===
                                        LIST_DETAIL_ELEMENTS.text_types.manual_appendex ? (
                                        <>
                                          {<>
                                            <div className="row mb-0 py-3 bg-light-orange border-light-orange g-0">
                                              <div className="col-md-6">
                                                <span style={{ color: "blue", fontWeight: "bolder" }}
                                                  className=" p-2"
                                                >Appendex</span>
                                                <h2 className="d-flex ms-2">


                                                  <HtmlRenderer
                                                    tag={"span"}
                                                    htmlContent={d.html ? d.html : d.standard_content}
                                                  />
                                                  <b style={{ color: "blue" }}>#ID:{index}</b>

                                                  {currentPage === "data-mapping" && (
                                                    <ManualDropDownMenu
                                                      editRef={editRef}
                                                      splitRef={splitRef}
                                                      setImageRef={setImageRef}
                                                      setContentNumber={setContentNumber}
                                                      handleMarking={handleMarking}
                                                      handleMarkAsParagraph={
                                                        handleMarkAsParagraph
                                                      }
                                                      handleMarkAsParaph={handleMarkAsParaph}
                                                      handleMarkAsHeading={handleMarkAsHeading}
                                                      updateContent={updateContent}
                                                      ignoreContent={ignoreContent}
                                                      splitText={splitText}
                                                      index={index}
                                                      index2={null}
                                                      handleEdit={handleEdit}
                                                      handleImageSet={handleImageSet}
                                                      handleManualIgnore={handleManualIgnore}
                                                      setSelectedImageInfo={setSelectedImageInfo}
                                                      level={
                                                        {
                                                          isChild: false,
                                                          p: null
                                                        }
                                                      }
                                                      d={d}
                                                      handleMarkAsFooter={handleMarkAsFooter}
                                                      handleMarkAsRefernce={handleMarkAsRefernce}
                                                      handleMarkAsAppendex={handleMarkAsAppendex}
                                                      handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                      handleRevert={handleRevert}
                                                      handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                      handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                      handleMarkFutherReadig={handleMarkFutherReadig}
                                                      handleMarkOnlineResources={handleMarkOnlineResources}
                                                      handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                      setCheckModal={setCheckModal}
                                                      setImageContent={setImageContent}
                                                      handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                      handleChangeTextType={handleChangeTextType}
                                                    />
                                                  )}

                                                </h2>

                                              </div>
                                              {currentPage === "data-mapping" && (
                                                <div className="col-md-6 text-end">
                                                  <ManualParentChildMapping
                                                    handleRadioButtonChangeState={
                                                      handleRadioButtonChangeState
                                                    }
                                                    linkChildWithParent={linkChildWithParent}
                                                    handleManualChildLink={handleManualChildLink}
                                                    index={index}
                                                    index2={null}
                                                    selectedValue={selectedValue}
                                                  />
                                                </div>
                                              )}
                                            </div>

                                            <>
                                              {
                                                d.children && d.children.length > 0 ? (
                                                  d.children.map((val, key) => (
                                                    <>
                                                      {

                                                        val.is_ignore != true && (
                                                          <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                            <h4>{index.key}</h4>
                                                            <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                              <p className="my-0">
                                                                <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                                {currentPage === "data-mapping" && (
                                                                  <ManualDropDownMenu
                                                                    editRef={editRef}
                                                                    splitRef={splitRef}
                                                                    setImageRef={setImageRef}
                                                                    setContentNumber={setContentNumber}
                                                                    handleMarking={handleMarking}
                                                                    handleMarkAsParagraph={
                                                                      handleMarkAsParagraph
                                                                    }
                                                                    handleMarkAsParaph={handleMarkAsParaph}
                                                                    handleMarkAsHeading={handleMarkAsHeading}
                                                                    updateContent={updateContent}
                                                                    ignoreContent={ignoreContent}
                                                                    splitText={splitText}
                                                                    index={key}
                                                                    index2={null}
                                                                    handleEdit={handleEdit}
                                                                    handleImageSet={handleImageSet}
                                                                    handleManualIgnore={handleManualIgnore}
                                                                    level={
                                                                      {
                                                                        isChild: true,
                                                                        p: index
                                                                      }
                                                                    }
                                                                    d={val}
                                                                    handleMarkAsFooter={handleMarkAsFooter}
                                                                    handleMarkAsRefernce={handleMarkAsRefernce}
                                                                    handleMarkAsAppendex={handleMarkAsAppendex}
                                                                    handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                    handleRevert={handleRevert}
                                                                    handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                    handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                    handleMarkFutherReadig={handleMarkFutherReadig}
                                                                    handleMarkOnlineResources={handleMarkOnlineResources}
                                                                    handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                    setCheckModal={setCheckModal}
                                                                    setImageContent={setImageContent}
                                                                    setSelectedImageInfo={setSelectedImageInfo}
                                                                    handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                    handleChangeTextType={handleChangeTextType}
                                                                  />
                                                                )}
                                                                <b style={{ color: "blue" }}>#ID : {key}</b> </p>
                                                              <span className="d-flex">

                                                              </span>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </>
                                                  ))
                                                ) : d.html_contents && d.html_contents.length > 0 ? (
                                                  d.html_contents.map((val, key) => (
                                                    <>
                                                      {
                                                        val.is_ignore != true && (
                                                          <div className="align-items-center d-flex" key={`c${val.id}`}>
                                                            <h4>{index.key}</h4>
                                                            <div className="ms-3 d-flex bg-secondary p-4 my-4 w-100">
                                                              <p className="my-0">
                                                                <HtmlRenderer tag={"span"} htmlContent={val.html} />
                                                                {currentPage === "data-mapping" && (
                                                                  <ManualDropDownMenu
                                                                    editRef={editRef}
                                                                    splitRef={splitRef}
                                                                    setImageRef={setImageRef}
                                                                    setContentNumber={setContentNumber}
                                                                    handleMarking={handleMarking}
                                                                    handleMarkAsParagraph={
                                                                      handleMarkAsParagraph
                                                                    }
                                                                    handleMarkAsParaph={handleMarkAsParaph}
                                                                    handleMarkAsHeading={handleMarkAsHeading}
                                                                    updateContent={updateContent}
                                                                    ignoreContent={ignoreContent}
                                                                    splitText={splitText}
                                                                    index={key}
                                                                    index2={null}
                                                                    handleEdit={handleEdit}
                                                                    handleImageSet={handleImageSet}
                                                                    handleManualIgnore={handleManualIgnore}
                                                                    level={
                                                                      {
                                                                        isChild: true,
                                                                        p: index
                                                                      }
                                                                    }
                                                                    d={val}
                                                                    handleMarkAsFooter={handleMarkAsFooter}
                                                                    handleMarkAsRefernce={handleMarkAsRefernce}
                                                                    handleMarkAsAppendex={handleMarkAsAppendex}
                                                                    handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                                    handleRevert={handleRevert}
                                                                    handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                                    handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                                    handleMarkFutherReadig={handleMarkFutherReadig}
                                                                    handleMarkOnlineResources={handleMarkOnlineResources}
                                                                    handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                                    setCheckModal={setCheckModal}
                                                                    setImageContent={setImageContent}
                                                                    setSelectedImageInfo={setSelectedImageInfo}
                                                                    handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                                    handleChangeTextType={handleChangeTextType}
                                                                  />
                                                                )}
                                                                <b style={{ color: "blue" }}>#ID : {key}</b>

                                                              </p>

                                                              <span className="d-flex">
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </>
                                                  ))
                                                ) : (
                                                  <></>
                                                )
                                              }

                                            </>
                                          </>


                                          }
                                        </>
                                      )
                                        : (
                                          <>
                                            {
                                              d.is_ignore != true &&
                                              <h2 className="text-center mb-0 py-3 bg-light-background d-flex justify-content-center border--light">
                                                <HtmlRenderer
                                                  tag={d.tag_name ?? "span"}
                                                  htmlContent={d.html ? d.html : d.standard_content}
                                                />
                                                <b style={{ color: "blue" }}>#ID:{index}</b>

                                                {currentPage === "data-mapping" && (
                                                  <ManualDropDownMenu
                                                    editRef={editRef}
                                                    splitRef={splitRef}
                                                    setImageRef={setImageRef}
                                                    setContentNumber={setContentNumber}
                                                    handleMarking={handleMarking}
                                                    handleMarkAsParagraph={handleMarkAsParagraph}
                                                    updateContent={updateContent}
                                                    handleMarkAsParaph={handleMarkAsParaph}
                                                    ignoreContent={ignoreContent}
                                                    handleMarkAsHeading={handleMarkAsHeading}
                                                    handleEdit={handleEdit}
                                                    handleImageSet={handleImageSet}
                                                    handleManualIgnore={handleManualIgnore}
                                                    handleChangeTextType={handleChangeTextType}
                                                    level={
                                                      {
                                                        isChild: false,
                                                        p: null
                                                      }
                                                    }
                                                    splitText={splitText}
                                                    index={index}
                                                    index2={null}
                                                    d={d}
                                                    handleMarkAsFooter={handleMarkAsFooter}
                                                    handleMarkAsRefernce={handleMarkAsRefernce}
                                                    handleMarkAsAppendex={handleMarkAsAppendex}
                                                    handleMarkASCOdeEditingDate={handleMarkASCOdeEditingDate}
                                                    handleRevert={handleRevert}
                                                    handleAsMarkBibloGrapgy={handleAsMarkBibloGrapgy}
                                                    handleMarkASApendexHeading={handleMarkASApendexHeading}
                                                    handleMarkFutherReadig={handleMarkFutherReadig}
                                                    handleMarkOnlineResources={handleMarkOnlineResources}
                                                    handleMarkAsGeneralHeading={handleMarkAsGeneralHeading}
                                                    setCheckModal={setCheckModal}
                                                    setImageContent={setImageContent}
                                                    setSelectedImageInfo={setSelectedImageInfo}
                                                    handleMarkasGLossaryTerm={handleMarkasGLossaryTerm}
                                                  />
                                                )}
                                                {currentPage === "style-mapping" && (
                                                  <StyleDropDownMenu
                                                    styleList={styleList}
                                                    id={d.id}
                                                    is_content={true}
                                                    styleHandler={styleMapping}
                                                  />
                                                )}
                                              </h2>
                                            }
                                          </>
                                        )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center my-2">No data found.</div>
                )}
            </>
          )}

          {
            tags.length > 0 &&
            <div
              className="d-flex justify-content-center"
            >
              <button
                type="button"
                className="btn btn--primary mt-5"
                onClick={saveContent}
                disabled={is_cracked}
              // onClick={() => saveReport()}
              // disabled={isLoading.is_cracking}
              >
                {
                  isCracking ?
                    <Spinner message={`${t("108", "Uploading and Cracking")}...`} />
                    : is_cracked ? t("198", "Saved") : t("180", "Save")
                }
              </button>
            </div>
          }
        </div>
        <SetTextTypeModals
          open={textTypeModal}
          handleClose={handleCloseTextTypeModal}
          textTypeDetail={textTypeDetail}
          setReload = {setReload}
          reload = {reload}
        // id={id}

        />

      </div>
    </>
  );
};

export default DataManualMapping;
