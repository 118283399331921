import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import TextFieldCustom from 'components/TextFieldCustom';
// import { LIST_DETAIL_ELEMENTS } from 'constants';
// import StandardsSelectCard from 'components/common/StandardsSelectCard';
import { displayNotification, sendAPIRequest } from 'helpers';
// import CustomCard from 'components/CustomCard';
// import CardHeader from 'components/common/CardHeader';
// import CardBody from 'components/common/CardBody';
// import Column from 'components/common/Column';
// import Row from 'components/common/Row';

function LinkGlossaryModal({ open, handleClose, loadGlossary, setrefetch, reFetch, componentLanguage = '', standardDetails = {} }) {
    const { t } = useTranslation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '3px solid #ff4815',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        padding: 0,
    };

    // const [selectedStandardOption, setSelectedStandardOption] = useState('terrestrial-code');
    // const [selectedRadio, setSelectedRadio] = useState({ animal_type: LIST_DETAIL_ELEMENTS.animal_type.terrestrial, standard: LIST_DETAIL_ELEMENTS.standard_type.codes });
    const [isLoading, setIsLoading] = useState(false)
    const [form, setFormData] = useState({
        term:"",
        text:"",
        language_id:componentLanguage
    });
    // const [formFields, setFormFields] = useState({
    //     term:"",
    //     text:"",
    //     language_id:""
    // });

    // const handleInputeChange = (index, field) => (e) => {
    //     const updatedFormFields = [...formFields];
    //     updatedFormFields[index][field] = e.target.value;
    //     setFormFields(updatedFormFields);
    // };
    // const handleAddFields = () => {
    //     setFormFields([
    //         ...formFields,
    //         { en_term: "", fr_term: "", sp_term: "", },
    //     ]);
    // };




    useEffect(() => {
        handleResetFilter();
    }, [standardDetails]);

    // const handleInputChange = (language, field) => (e) => {
    //     setFormData((prevForm) => ({
    //         ...prevForm,
    //         [language]: {
    //             ...prevForm[language],
    //             [field]: e.target.value
    //         }
    //     }));
    // };

    const handleResetFilter = () => {
        setFormData({
        term:"",
        text:"",
        language_id:componentLanguage
        })
    }
    const handleCloseModal = () => {
        handleResetFilter();
        handleClose();
    }
    const handleSave = async () => {
        if(!form.term || !form.text) {
            displayNotification('warning', 'Please filed required fields');
            return;
        }
        setIsLoading(true);
        try {
            const ret = await sendAPIRequest(
                `/api/draft-glossaries/`, "POST",{
                    glossary_term:form.term ?? '',
                    glossary_text:form.text ?? '',
                    standard_type:standardDetails.standard_type ?? '',
                    animal_type:standardDetails.animal_type ?? '',
                    language_id:componentLanguage,
                } 
            );
            const res = await ret.json();
            if (res.success && res.status === 201) {
                setrefetch(!reFetch);
                loadGlossary();
                displayNotification('success', 'Successfully saved glossary term');
                handleCloseModal();
            }
            else {
                displayNotification('error', res.message);
            }
        } catch (e) {
            console.log({ e });
            displayNotification('error', 'Error saving glossary term');
        }
        setIsLoading(false);
    }

    // const isFormValid = () => {
    //     return Object.values(form).every(languageForm =>
    //         languageForm.glossary_term.trim() !== '' && languageForm.glossary_text.trim() !== ''
    //     );
    // }

    // const handleRemoveFields = (index) => {
    //     const updatedFormFields = [...formFields];
    //     updatedFormFields.splice(index, 1);
    //     setFormFields(updatedFormFields);
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add Glossary Term
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: '#000', margin: '0' }} />
                    <Box sx={{ padding: '20px' }}>
                        <div className="row">
                            <Typography variant="h7" sx={{ fontWeight: 'bold', marginY: '10px' }}>
                                Glossary Term
                            </Typography>
                            <div className='col-12 mb-4'>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Chapter"
                                    label={t("-1", "Term")}
                                    fullWidth
                                    rows={4}
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    value={form.term}
                                    name="term"
                                    onChange={handleChange}
                                    size='small'
                                />
                            </div>
                            <Typography variant="h7" sx={{ fontWeight: 'bold', marginY: '10px' }}>
                                Definition
                            </Typography>
                            <div className='col-12 mb-4'>
                                <TextFieldCustom
                                    variant="outlined"
                                    id="Chapter"
                                    label={t("-1", "Definition")}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    required={true}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        border: "#ff4815",
                                        width: "99%",
                                    }}
                                    value={form.text}
                                    name="text"
                                    onChange={handleChange} 
                                    />
                            </div>
                        </div>
                        <div className='row px-2'>
                            <div className="col-12 text-center mt-3 mb-3">
                                <button
                                    className="btn btn--primary  font-custom fs-6 me-2"
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    {t("-1", "Close")}
                                </button>
                                <button
                                    className="btn btn--outline font-custom fs-6"
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleSave()
                                    }}
                                >
                                    {isLoading ? t("-1", "Saving...") : t("-1", "Save")}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default LinkGlossaryModal
